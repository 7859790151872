import { TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import { Customer } from "shared";
import {
  CustomerLocation,
  Shift,
} from "shared/src/customerLocation/CustomerLocation.types";
import { uid } from "uid";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { generateEmptyContactInformation } from "../user/User.utils";
import i18n from "../../i18n/i18n";

/**
 * Util method to convert customerLocations into TableEntries
 * @param locations to convert
 * @param navigate navigateFunction
 * @returns Array of created TableRows
 */
export const convertCustomerLocationsIntoTableEntries = (
  locations: CustomerLocation[],
  navigate: NavigateFunction
): TableRow[] =>
  locations.map((location) => ({
    id: location.id,
    onClick: () =>
      navigate("/customer/location/create", { state: { location } }),
    content: [
      location?.name || "-",
      location?.contact.city || "-",

      <EditIcon
        title={i18n.t("general.icons.edit")}
        width={30}
        onClick={(evt) => {
          evt.stopPropagation();
          navigate("/customer/location/create", { state: { location } });
        }}
      />,
    ],
  }));

/**
 * Util method to convert customerLocations into TableEntries
 * @param locations to convert
 * @param customers list of all customers to get the name of the customer for the locations
 * @param navigate navigateFunction
 * @returns Array of created TableRows
 */
export const convertCustomerLocationsIntoOverviewTableEntries = (
  locations: CustomerLocation[],
  navigate: NavigateFunction,
  customers?: Customer[]
): TableRow[] => {
  return locations.map((location) => {
    const customer: Customer | undefined = customers?.find(
      (customer) => customer.id === location.customerId
    );
    return {
      id: location.id,
      onClick: () =>
        navigate("/customer/location/create", { state: { location } }),
      content: [
        customer?.numberRangeNumber || "-",
        customer?.parentCompany || "-",
        customer?.name || "-",
        location?.name || "-",
        (location?.contact.street || "-") +
          " " +
          (location?.contact.streetNumber + " " || "-") +
          (location?.contact.city || "-"),
        location.disabled
          ? i18n.t("pages.customerLocation.overview.deactivated")
          : i18n.t("pages.customerLocation.overview.active"),
        <div>
          <EditIcon
            title={i18n.t("general.icons.edit")}
            width={30}
            onClick={() =>
              navigate("/customer/location/create", { state: { location } })
            }
          />
        </div>,
      ],
    };
  });
};

/**
 * Util method to generate a new CustomerLocation
 * @param override  override setter
 * @returns created emptyCustomerLocation
 */
export const generateEmptyCustomerLocation = (
  override?: Partial<CustomerLocation>
): CustomerLocation => ({
  id: undefined!,
  annotation: "",
  contact: generateEmptyContactInformation(),
  contacts: [],
  createDate: new Date(),
  createdBy: "",
  customerId: "",
  disabled: false,
  lastUpdate: undefined!,
  name: "",
  updatedBy: "",
  officeDistances: new Map<string, number>(),
  officeDrivingTimes: new Map<string, number>(),
  officeDrivingFlatRate: new Map<string, number>(),
  ownLocation: false,
  ...override,
});

/**
 * Util method to create an empty shift
 * @param override override setter to adjust the generated Shift as needed
 * @returns  generated Shift
 */
export const generateEmptyShift = (override?: Partial<Shift>): Shift => ({
  createDate: new Date(),
  createdBy: "",
  customerId: "",
  endTime: 0,
  startTime: 0,
  id: uid(),
  lastUpdate: new Date(),
  locationId: "",
  type: undefined!,
  updatedBy: "",
  ...override,
});
