import { Dropdown, Input, TableRow } from "@sam/components";
import { Language, OfferedTask, Task, generateDropdownOptions } from "shared";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { generateEmptyTask } from "../task/task.utils";
import i18n from "../../i18n/i18n";

/**
 * Util mehthod to create tableRows with offered tasks to be edited
 * @param offeredTasks array of all positions
 * @param tasks all available tasks
 * @param onChange update mthod
 * @param setOfferedTaskIdToDelete sets the id of a position to delete it
 * @returns array of tableRows
 */
export const convertTasksIntoDeliveryNoteTableEntries = (
  offeredTasks: OfferedTask[],
  tasks: Task[],
  onChange: (updatedTask: OfferedTask[]) => void,
  setOfferedTaskIdToDelete: (taskId: string) => void,
  language: Language
): TableRow[] =>
  offeredTasks.map((rowTask, index) => ({
    id: rowTask.id,
    content: [
      index + 1,
      <Dropdown
        create
        options={generateDropdownOptions(tasks, "title", "id")}
        selectedOption={rowTask.task.id}
        onChange={(taskId) =>
          onChange(
            offeredTasks.map((task) =>
              rowTask.id === task.id
                ? {
                    ...task,
                    task:
                      tasks.find((task) => task.id === taskId) ||
                      generateEmptyTask({ identifier: taskId, title: taskId }),
                  }
                : task
            )
          )
        }
      />,
      <Input
        type="text"
        value={rowTask.task?.translations?.get(language)}
        onChange={(description) => {
          onChange(
            offeredTasks.map((singleTask) =>
              singleTask.id === rowTask.id
                ? {
                    ...singleTask,
                    task: { ...singleTask.task, description },
                  }
                : singleTask
            )
          );
        }}
      />,
      <Input
        value={rowTask.amount}
        type="number"
        onChangeNumber={(amount) => {
          onChange(
            offeredTasks.map((task) =>
              task.id === rowTask.id
                ? {
                    ...task,
                    totalAmount: rowTask.task.price * amount,
                    amount,
                  }
                : task
            )
          );
        }}
      />,
      <Input
        value={rowTask.task.price}
        type="number"
        onChangeNumber={(price) => {
          onChange(
            offeredTasks.map((task) =>
              rowTask.id === task.id
                ? {
                    ...task,
                    task: { ...rowTask.task, price },
                    totalAmount: rowTask.amount * price,
                  }
                : task
            )
          );
        }}
      />,
      <Input
        value={rowTask.totalAmount}
        type="number"
        disabled
        onChangeNumber={() => {}}
      />,

      <DeleteIcon
        title={i18n.t("general.icons.delete")}
        width={30}
        color="#BC2E46"
        onClick={() => setOfferedTaskIdToDelete(rowTask.id)}
      />,
    ],
  }));
