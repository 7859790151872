import { Box, Input, TopBar, Wysiwyg } from "@sam/components";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MailTemplate,
  MailType,
  createNewMailTemplate,
  generateNotification,
  getMailTemplateForMailType,
  updateMailTemplate,
} from "shared";
import { Language } from "shared/src/Language/Language.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import { generateNewMailTemplate } from "../../utils/mail/Mail.utils";
import { MailVariableInfo } from "../../components/MailVariableInfo/MailVariableInfo";

export const MailConfigEdit: React.FC = () => {
  const { axios } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ type: MailType }>();
  const [mailTemplate, setMailTemplate] = useState<MailTemplate>();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  // Hook to load the template for the type or create a new one if no template was found
  useEffect(() => {
    if (!location.state?.type) return navigate(-1);
    getMailTemplateForMailType(axios, location.state.type).then((res) => {
      if (res) setMailTemplate(res);
      else
        setMailTemplate(
          generateNewMailTemplate({ type: location.state!.type })
        );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.type]);

  /**
   * Helper method to update or create a mailTemplate
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;
    if (mailTemplate?.id)
      updateMailTemplate(axios, mailTemplate).then((updatedTemplate) => {
        if (updatedTemplate && redirect) navigate("/mail");
        else if (updatedTemplate)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
      });
    else
      mailTemplate &&
        createNewMailTemplate(axios, mailTemplate).then((createdTemplate) => {
          if (createdTemplate && redirect) navigate("/mail");
          else if (createdTemplate)
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.saveSuccessfull"),
            });
        });
  };

  /**
   * Helper method to update a translation
   * @param language to update
   * @param content to update
   */
  const handleUpdateTranslation = (
    language: Language,
    content: string
  ): void => {
    if (!mailTemplate) return;
    const updatedValues: Map<Language, string> = new Map<Language, string>(
      mailTemplate?.mailContent
    );
    updatedValues.set(language, content);
    setMailTemplate({ ...mailTemplate, mailContent: updatedValues });
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        title={t("pages.mailConfig.edit.topBarHeadline", {
          replace: { type: t(`general.mailTypes.${location.state?.type}`) },
        })}
        onBackClick={() => navigate(-1)}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>

      {location.state?.type === MailType.DUNNING && (
        <Box>
          <MailVariableInfo type={MailType.DUNNING} />
        </Box>
      )}
      {mailTemplate &&
        Object.values(Language).map((language) => (
          <Box title={t(`general.languages.${language}`)}>
            <Input
              label={t("pages.mailConfig.edit.subject")}
              type="text"
              value={mailTemplate.subject.get(language)}
              onChange={(subject) => {
                const updatedValue: Map<Language, string> = new Map<
                  Language,
                  string
                >(mailTemplate?.subject);
                updatedValue.set(language, subject);
                setMailTemplate({ ...mailTemplate, subject: updatedValue });
              }}
            />
            <p>{t("pages.mailConfig.edit.customerGreeting")}</p>
            <Wysiwyg
              initialContent={mailTemplate?.mailContent.get(language)}
              onChange={(content) => handleUpdateTranslation(language, content)}
            />
            <p>{t("pages.mailConfig.edit.officeSignature")}</p>
          </Box>
        ))}
    </form>
  );
};
