import { DropdownProps, Option } from "./Dropdown.types";
import "./Dropdown.scss";
import Select from "react-dropdown-select";
import { useMemo } from "react";

export const Dropdown: React.FC<DropdownProps> = ({
  disabled = false,
  required = false,
  options = [],
  onChange,
  searchable = true,
  selectedOption,
  selectedMultiOptions,
  label,
  multi,
  create,
  onCreateNew,
  onChangeMultiple,
  autoFocus,
  onClose,
  clearable,
  defaultMenuIsOpen,
  smaller,
  minWidth,
}) => {
  const selectedOptionFromOptions: Option[] = useMemo(
    (): Option[] =>
      multi
        ? options.filter((option) =>
            selectedMultiOptions?.includes(option.value)
          )
        : options.find((option) => option.value === selectedOption)
        ? [options.find((option) => option.value === selectedOption)!]
        : [],
    [multi, options, selectedMultiOptions, selectedOption]
  );

  return (
    <div
      className="dropdown-component__wrapper"
      data-testid="dropdownComponent"
    >
      {label && <p className="dropdown-component__label">{label}</p>}
      <Select
        style={{ minWidth: minWidth ?? "" }}
        clearable={clearable}
        data-testid="drop"
        required={required}
        disabled={disabled}
        values={selectedOptionFromOptions}
        autoFocus={autoFocus}
        onDropdownClose={onClose}
        defaultMenuIsOpen={defaultMenuIsOpen}
        options={options}
        dropdownPosition="auto"
        selectAll={multi}
        onChange={(option) => {
          if (onChangeMultiple)
            onChangeMultiple(option.map((value) => value.value));
          else if (option[0]?.value && option.length === 1 && onChange)
            onChange(option[0].value);
          else if (onChange) onChange("");
        }}
        searchable={searchable}
        className={[
          "dropdown-component__select",
          searchable ? "searchable" : "non-searchable",
          smaller ? "smaller" : "",
        ].join(" ")}
        dropdownGap={-5}
        multi={!!multi}
        onCreateNew={onCreateNew}
        create={!!create}
      />
    </div>
  );
};
