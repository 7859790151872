import { Box, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createAndSendDunning,
  generateNotification,
  getCustomerUserById,
  getDunningPreviewDocument,
  Language,
  MailConfiguration,
  MailType,
  Order,
  Receipt,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import { MailPopup } from "../../components/mailPopup/MailPopup";
import { useUser } from "../../components/UserContext";
import { downloadFile } from "../../utils/files/Files.utils";
import { convertDueInvoicesIntoTableEntries } from "../../utils/invoice/Invoice.utils";
import { generateEmptyMailConfiguration } from "../../utils/mail/Mail.utils";

export const DunningOverview: React.FC = () => {
  const { t } = useTranslation();
  const { axios, user, updateContextUser } = useUser();
  const [selectedReceipt, setSelectedReceipt] = useState<string>("");
  const [mailConfig, setMailConfig] = useState<MailConfiguration>(
    generateEmptyMailConfiguration({ type: MailType.DUNNING })
  );
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: dueInvoices } = useData("INVOICE_DUE", {
    config: { fallbackData: [] },
  });
  const { data: allPayments } = useData("PAYMENTS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCurrencies } = useData("CURRENCY_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });

  const { data: allOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allDunningLevels } = useData("DUNNING_ALL", {
    config: { fallbackData: [] },
  });

  const maxDunningLevel: number = useMemo(
    (): number => allDunningLevels.sort((a, b) => b.level - a.level)[0]?.level,
    [allDunningLevels]
  );

  /**
   * Callback method to handle the selection of a receipt for dunning
   * @param receipt selected receipt
   * @param order to get the invoiceRecipient
   */
  const handleReceiptSelect = useCallback(
    (receipt: Receipt, order: Order): void => {
      getCustomerUserById(axios, order.invoiceRecipient.customerUserId).then(
        (loadedContact) => {
          setMailConfig({
            ...mailConfig,
            receiver: loadedContact?.contact.mail || "",
            language: user.language || Language.DE,
          });
          setSelectedReceipt(receipt.id);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mailConfig, user.language]
  );

  /**
   * Holds due invoices as TableRows
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertDueInvoicesIntoTableEntries(
        dueInvoices,
        handleReceiptSelect,
        allOrders,
        allCustomers,
        maxDunningLevel,
        allCompanies,
        allCurrencies,
        allPayments
      ),
    [
      allCompanies,
      allCurrencies,
      allCustomers,
      allOrders,
      allPayments,
      dueInvoices,
      handleReceiptSelect,
      maxDunningLevel,
    ]
  );

  /**
   * Submit method to create the selected dunnings and send them via mail
   * @returns Promise that is used for the button loading state
   */
  const handleSendMail = async (): Promise<void> => {
    return createAndSendDunning(axios, {
      ...mailConfig,
      activeUserId: user.id,
      invoiceId: selectedReceipt,
    }).then((success) => {
      if (success) {
        generateNotification({
          type: NotificationType.SUCCESS,
          value: t("general.notification.success.sendDunnings"),
        });
      }
    });
  };

  /**
   * Util method to get and download the preview of the dunning
   */
  const handlePreviewDownload = () => {
    getDunningPreviewDocument(
      axios,
      selectedReceipt,
      user.id,
      mailConfig.language || Language.DE
    ).then((document) => {
      document &&
        downloadFile(document, t("pages.dunningOverview.dunningFilename"));
    });
  };
  return (
    <>
      <TopBar
        title={t("pages.dunningOverview.topBarHeadline")}
        onSearch={setFilterValue}
      />
      <MailPopup
        isOpen={!!selectedReceipt}
        mailConfig={mailConfig}
        toggleOpen={() => setSelectedReceipt("")}
        sendMail={handleSendMail}
        userId={user.id}
        referenceId={selectedReceipt}
        updateConfig={setMailConfig}
        download={handlePreviewDownload}
      />
      <Box>
        <Table
          filterValue={filterValue}
          tableConfig={user.tableConfig?.get(TableKey.DUNNING_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.DUNNING_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          rows={rows}
          header={
            t("pages.dunningOverview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
