import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MailType, MailVariables } from "shared";
import { ReactComponent as UpArrow } from "../../assets/up_arrow.svg";

interface MailInfoProps {
  type: MailType;
}

export const MailVariableInfo: React.FC<MailInfoProps> = ({ type }) => {
  const { t } = useTranslation();
  const defaultVariables: MailVariables[] = useMemo(
    (): MailVariables[] => [
      MailVariables.CUSTOMER_NAME,
      MailVariables.GREETING,
      MailVariables.USER_FIRSTNAME,
      MailVariables.USER_LASTNAME,
      MailVariables.CUSTOMER_CONTACT_NAME,
      MailVariables.SIGNATURE,
    ],
    []
  );
  const [availableVariables, setAvailableVariables] =
    useState<MailVariables[]>(defaultVariables);
  const [isOpen, toggleOpen] = useState<boolean>(false);

  //Hook to update the Mailvariables for a given mailType
  useEffect(() => {
    switch (type) {
      case MailType.DUNNING:
        setAvailableVariables([
          ...defaultVariables,
          MailVariables.ORDER_NUMBER,
          MailVariables.CREATE_DATE,
          MailVariables.DUNNING_LEVEL,
          MailVariables.DUNNING_DUE_ON,
          MailVariables.COMPANY,
          MailVariables.DUE_ON,
        ]);
        break;
      default:
        break;
    }
  }, [defaultVariables, type]);

  return (
    <div className="mail-variables__wrapper">
      {isOpen ? (
        <UpArrow
          className="mail-variables__wrapper__up-arrow"
          onClick={() => toggleOpen(!isOpen)}
        />
      ) : (
        <UpArrow
          className="mail-variables__wrapper__down-arrow"
          onClick={() => toggleOpen(!isOpen)}
        />
      )}
      <p>{t("pages.mailConfig.edit.availableValues")}</p>
      {isOpen && (
        <ul className="three-columns">
          {availableVariables.map((value) => (
            <li className="mail-variables__wrapper__values__value" key={value}>
              {t(`general.mailVariables.placeholder.${value}`)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
