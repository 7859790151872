import { AxiosInstance } from "axios";
import { SAPExportDataModel } from "./SapExport.types";
import i18n from "../../../client/src/i18n/i18n";
import { generateNotification } from "../notification/notification.util";
import { NotificationType } from "../notification/notification.types";

/**
 * GET Method to create a preview on the sap export
 * @param axios instance of axios
 * @param receiptIds receipts to display
 * @returns  List of exportData filled with tax information and more
 */
export const getReceiptExportPreview = async (
  axios: AxiosInstance,
  receiptIds: string[]
): Promise<SAPExportDataModel[]> => {
  return axios
    .get("/export/sap/preview", {
      params: { receiptIds: receiptIds.toString() },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading preview data", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getReceiptSAPExportPreview"),
      });
      return [];
    });
};
