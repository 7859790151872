import { Box, Button, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  InvoiceState,
  Receipt,
  ReceiptType,
  getAllReceiptsByTypeAndState,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { useUser } from "../../components/UserContext";
import { convertInvoicesIntoTableRows } from "../../utils/invoice/Invoice.utils";

export const InvoiceOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios, user, updateContextUser, selectedOffices } = useUser();
  const [invoices, setInvoices] = useState<Receipt[]>([]);
  const [selectedStates, setSelectedStates] = useState<InvoiceState[]>([]);
  const [selectedReceiptTypes, setSelectedReceiptTypes] = useState<
    ReceiptType[]
  >([ReceiptType.INVOICE]);
  const [filterValue, setFilterValue] = useState<string>("");

  const { data: allOrders } = useData("ORDER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allProjects } = useData("PROJECT_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allCustomerLocations } = useData("CUSTOMER_LOCATION_ALL", {
    config: { fallbackData: [] },
  });
  const { data: activeCurrencyConversions } = useData(
    "CURRENCY_CONVERSIONS_ACTIVE",
    {
      config: { fallbackData: [] },
    }
  );
  const { data: allCurrencies } = useData("CURRENCY_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCompanies } = useData("COMPANY_ALL", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows out of the loaded invoices and filtered by the selected state
   */
  const rows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertInvoicesIntoTableRows(
        invoices,
        allOrders,
        allProjects,
        allCustomers,
        allCustomerLocations,
        navigate,
        activeCurrencyConversions,
        allCurrencies,
        allCompanies
      ),
    [
      activeCurrencyConversions,
      allCompanies,
      allCurrencies,
      allCustomerLocations,
      allCustomers,
      allOrders,
      allProjects,
      invoices,
      navigate,
    ]
  );

  // Hook to load the invoices for the selected state
  useEffect(() => {
    const typesToLoad: ReceiptType[] = [];
    const statesToLoad: InvoiceState[] = [];

    if (selectedReceiptTypes.length === 0)
      typesToLoad.push(...Object.values(ReceiptType));
    else typesToLoad.push(...selectedReceiptTypes);

    if (selectedStates.length === 0)
      statesToLoad.push(...Object.values(InvoiceState));
    else statesToLoad.push(...selectedStates);

    getAllReceiptsByTypeAndState(
      axios,
      selectedReceiptTypes,
      selectedStates
    ).then(setInvoices);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStates, selectedReceiptTypes, selectedOffices]);

  return (
    <>
      <TopBar
        title={t("pages.invoice.overview.topBarHeadline")}
        onSearch={setFilterValue}
      />
      <Box>
        <div className="receipt-overview__filter-box">
          <p className="receipt-overview__filter-box__label">
            {t("pages.invoice.overview.type")}
          </p>
          <div className="three-columns">
            {Object.values(ReceiptType).map((type) => (
              <Button
                value={t(`general.receiptType.${type}`)}
                active={selectedReceiptTypes.includes(type)}
                onClick={() =>
                  setSelectedReceiptTypes(
                    selectedReceiptTypes.includes(type)
                      ? selectedReceiptTypes.filter(
                          (selectedType) => selectedType !== type
                        )
                      : [...selectedReceiptTypes, type]
                  )
                }
              />
            ))}
          </div>

          <p className="receipt-overview__filter-box__label">
            {t("pages.invoice.overview.state")}
          </p>
          <div className="three-columns">
            {Object.values(InvoiceState).map((state) => (
              <Button
                value={t(`general.invoiceState.${state}`)}
                active={selectedStates.includes(state)}
                onClick={() =>
                  setSelectedStates(
                    selectedStates.includes(state)
                      ? selectedStates.filter(
                          (prevState) => prevState !== state
                        )
                      : [...selectedStates, state]
                  )
                }
              />
            ))}
          </div>
        </div>
      </Box>
      <Box>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.INVOICE_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.INVOICE_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.invoice.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
