import dayjs, { Dayjs } from "dayjs";
import { useCallback, useMemo } from "react";
import { Car } from "shared";
import { Schedule } from "shared/src/project/Project.types";

interface CarsAvailableProps {
  cars: Car[];
  schedules: Schedule[];
  startDate: Dayjs;
  carsHeader: string;
}
export const CarsAvailable: React.FC<CarsAvailableProps> = ({
  cars,
  startDate,
  schedules,
  carsHeader,
}) => {
  const endDate: dayjs.Dayjs = useMemo(
    (): dayjs.Dayjs => startDate.add(4, "day"),
    [startDate]
  );

  /**
   * Helper to get the available cars for the given day
   *
   * @param date The date to check for available cars
   */
  const getAvailableCars = useCallback(
    (date: dayjs.Dayjs): Car[] => {
      const filteredSchedules: Schedule[] = schedules.filter((schedule) =>
        date.isSame(dayjs(schedule.scheduleDate), "date")
      );
      if (filteredSchedules.length === 0) return cars;
      const scheduledCars: string[] = filteredSchedules
        .flatMap((schedule) => [...schedule.scheduledCars.values()])
        .flat();

      return cars.filter((car) => !scheduledCars.includes(car.id));
    },
    [cars, schedules]
  );

  /**
   * Util to fill in the actual available cars for each planning day
   */
  const availableCarsElements: JSX.Element[] = useMemo((): JSX.Element[] => {
    let targetArray: JSX.Element[] = [
      <div className="project-planning__entry-row__cell" />,
    ];
    for (
      let currDate = startDate;
      currDate.isBefore(endDate) || currDate.isSame(endDate);
      currDate = currDate.add(1, "day")
    ) {
      const availableCars: Car[] = getAvailableCars(currDate);
      targetArray = targetArray.concat(
        <div
          className={`project-planning__entry-row__cell ${
            currDate.day() === 0 || currDate.day() === 6 ? "weekend-row" : ""
          }`}
        >
          {availableCars.map((user) => (
            <div className="project-planning__entry-row__swimlane__days__entry__car">
              {user.registrationPlate}
            </div>
          ))}
        </div>
      );
    }
    return targetArray;
  }, [endDate, getAvailableCars, startDate]);

  /**
   * Util to generate the header information which includes how many users are available
   */
  const headerElements: JSX.Element[] = useMemo((): JSX.Element[] => {
    const targetArray: JSX.Element[] = [
      <p className="project-planning__header-wrapper__header__date" />,
    ];
    for (
      let currDate = startDate;
      currDate.isBefore(endDate) || currDate.isSame(endDate);
      currDate = currDate.add(1, "day")
    ) {
      const availableCarsAmount: number = getAvailableCars(currDate).length;
      targetArray.push(
        <p
          className={`project-planning__header-wrapper__header__date ${
            currDate.day() === 0 || currDate.day() === 6 ? "weekend-row" : ""
          }`}
        >{`${carsHeader} ${availableCarsAmount}`}</p>
      );
    }
    return targetArray;
  }, [carsHeader, endDate, getAvailableCars, startDate]);

  return (
    <div>
      <div className="project-planning__header-wrapper">
        <div className="project-planning__header-wrapper__header">
          {headerElements}
        </div>
      </div>
      <div className="project-planning__entry-row">{availableCarsElements}</div>
    </div>
  );
};
