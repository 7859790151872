import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Customer,
  CustomerArticle,
  CustomerUser,
  DocumentBucket,
  MailType,
  Offer,
  OfferMailConfiguration,
  OfferProvidedBy,
  OfferState,
  Office,
  Order,
  OrderWithOffer,
  PaymentCondition,
  ProjectInvoiceType,
  SmtpConfiguration,
  Task,
  acceptOffer,
  createNewOffer,
  createNewOrder,
  generateDropdownOptions,
  generateDropdownOptionsForLanguage,
  generateNotification,
  getActiveCustomerArticlesForCustomer,
  getOfferPDF,
  getOrderById,
  rejectOffer,
  sendNewOffer,
  updateOrderAndOffer,
  useData,
} from "shared";
import { Language } from "shared/src/Language/Language.types";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { SoftLockedPopup } from "../../components/SoftLockedPopup";
import { useUser } from "../../components/UserContext";
import { FileOverview } from "../../components/files/FileOverview";
import { MailPopup } from "../../components/mailPopup/MailPopup";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { generateDropdownOptionsForBusinessArea } from "../../utils/businessArea/BusinessArea.utils";
import { filterValidCustomers } from "../../utils/customer/Customer.utils";
import { downloadFile } from "../../utils/files/Files.utils";
import { generateEmptyMailConfiguration } from "../../utils/mail/Mail.utils";
import {
  convertOfferedTasksIntoTableEntries,
  generateDropdownOptionsForCustomers,
  generateEmptyOffer,
  generateEmptyOfferWithStandardTasks,
  generateEmptyOfferedTask,
  generateEmptyOrder,
} from "../../utils/order/Order.utils";

export const OfferCreate: React.FC<{ createProject?: boolean }> = ({
  createProject = false,
}) => {
  const { axios, user, updateFootnoteConfig } = useUser();
  const location = useLocation<{ offer?: Offer; isReadOnly?: boolean }>();
  const navigate = useNavigate();
  const [showMailPopup, toggleMailPopup] = useState<boolean>(false);
  const [softLockedCustomer, setSoftLockedCustomer] = useState<Customer>();

  const [documentToShow, setDocumentToShow] = useState<Blob>();
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const { data: loadedOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCurrencys } = useData("CURRENCY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedCustomers } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData(
    "CUSTOMER_LOCATION_ALL_ACTIVE",
    {
      config: { fallbackData: [] },
    }
  );
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  const { data: loadedTasks } = useData("TASKS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedStandardTasks } = useData("TASKS_ALL_STANDARD", {
    config: { fallbackData: [] },
  });
  const { data: loadedCompanies } = useData("COMPANY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const [orderToCreate, setOrderToCreate] = useState<Order>(
    generateEmptyOrder()
  );
  const [offerToEdit, setOfferToEdit] = useState<Offer>(
    location.state?.offer ? location.state.offer : generateEmptyOffer()
  );
  const [offeredTaskIdToDelete, setOfferedTaskIdToDelete] = useState<string>();
  const [additionalReceiverContacts, setAdditionalReceiverContacts] = useState<
    Option[]
  >([]);
  const [customerArticles, setCustomerArticles] = useState<CustomerArticle[]>(
    []
  );
  const [mailConfig, setMailConfig] = useState<OfferMailConfiguration>({
    ...generateEmptyMailConfiguration(),
    type: MailType.OFFER,
    activeUser: user.id,
    language: offerToEdit.language,
    offerId: offerToEdit.id,
  });

  const { data: loadedPaymentConditions } = useData("PAYMENT_CONDITION_ALL", {
    params: { fallbackData: [] },
  });

  const { t } = useTranslation();

  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(offerToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerToEdit.createDate]);

  //useEffect hook to get the customer specific articles
  useEffect(() => {
    if (!offerToEdit.customerId) return;
    getActiveCustomerArticlesForCustomer(axios, offerToEdit.customerId).then(
      setCustomerArticles
    );
  }, [axios, offerToEdit.customerId]);
  /**
   * location of the customer that is selected for the workLocation
   */
  const workingLocations: CustomerLocation[] = useMemo(
    (): CustomerLocation[] =>
      loadedCustomerLocations.filter(
        (location) =>
          location.customerId === orderToCreate.workingLocation.customerId
      ),
    [loadedCustomerLocations, orderToCreate.workingLocation.customerId]
  );

  /**
   * location of the customer that is selected for the project
   */
  const customerLocations: CustomerLocation[] = useMemo(
    (): CustomerLocation[] =>
      loadedCustomerLocations.filter(
        (location) => location.customerId === offerToEdit.customerId
      ),
    [loadedCustomerLocations, offerToEdit.customerId]
  );

  /**
   * DropdownOptions for the businessAreas
   */
  const businessAreaOptions: Option[] = useMemo(
    (): Option[] =>
      generateDropdownOptionsForBusinessArea(
        loadedBusinessAreas.filter(
          (area) => area.companyId === orderToCreate.companyId
        )
      ),
    [loadedBusinessAreas, orderToCreate.companyId]
  );

  //Dropdown options for payment contition
  const paymentOptions: Option[] = useMemo((): Option[] => {
    if (!loadedPaymentConditions) return [];
    else
      return loadedPaymentConditions.map((condition) => ({
        label: condition.description,
        value: condition.id,
      }));
  }, [loadedPaymentConditions]);

  /**
   * Holds options for customerLocations
   */
  const customerLocationOptions: Option[] = useMemo(
    (): Option[] => generateDropdownOptions(customerLocations, "name", "id"),
    [customerLocations]
  );

  /**
   * Holds the selected customer
   */
  const selectedCustomer: Customer | undefined = useMemo(
    (): Customer | undefined =>
      loadedCustomers.find(
        (customer) => customer.id === orderToCreate.customerId
      ),
    [loadedCustomers, orderToCreate.customerId]
  );

  //all existing companies to choose
  const companyDropdownOptions: Option[] = useMemo(
    () =>
      loadedCompanies.map((company) => ({
        label: company.name,
        value: company.id,
      })),
    [loadedCompanies]
  );
  //Hook to load the existing offer if the location state contains the order
  useEffect(() => {
    if (!axios || !location.state?.offer) return;
    getOrderById(axios, location.state.offer.orderId).then((loadedOrder) => {
      if (loadedOrder) setOrderToCreate(loadedOrder);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * Holds the options for the invoice recipient locations
   */
  const invoiceLocationOptions: Option[] = useMemo((): Option[] => {
    if (!orderToCreate?.invoiceRecipient.customerId) return [];
    const locations: CustomerLocation[] = customerLocations.filter(
      (location) =>
        location.customerId === orderToCreate?.invoiceRecipient.customerId
    );
    return generateDropdownOptions(locations, "name", "id");
  }, [customerLocations, orderToCreate?.invoiceRecipient.customerId]);

  //set the SMTP config and the receiver based on the selected office
  useEffect(() => {
    if (!orderToCreate.officeId || !loadedCustomerUsers) return;
    const recipient: string =
      loadedCustomerUsers.find(
        (user) => user.id === orderToCreate.invoiceRecipient?.customerUserId
      )?.contact.mail || "";
    const officeMailConfig: SmtpConfiguration | undefined = loadedOffices.find(
      (office) => office.id === orderToCreate.officeId
    )?.accountingMailConfiguration;
    if (!officeMailConfig) return;
    setMailConfig((old) => ({
      ...old,
      smtpConfig: officeMailConfig,
      offerId: offerToEdit.id,
      receiver: recipient,
    }));
  }, [
    loadedOffices,
    orderToCreate.officeId,
    offerToEdit.id,
    orderToCreate.invoiceRecipient?.customerUserId,
    loadedCustomerUsers,
  ]);

  // in case this is a new offer and there are standard tasks this useEffect adds the
  // standard tasks to the new offer
  useEffect(() => {
    if (
      !loadedStandardTasks ||
      !!orderToCreate.id ||
      offerToEdit.id ||
      offerToEdit.offeredTasks.length > 0 ||
      !orderToCreate.officeId ||
      !orderToCreate.currencyId ||
      !selectedCustomer ||
      !offerToEdit.customerId
    )
      return;
    setOfferToEdit(
      generateEmptyOfferWithStandardTasks(
        loadedStandardTasks,
        orderToCreate.officeId,
        orderToCreate.currencyId,
        selectedCustomer.customPrices,
        { ...offerToEdit }
      )
    );
    // needs to be disabled as otherwise the deletion of the last standard item is not possible
  }, [
    loadedStandardTasks,
    offerToEdit.id,
    offerToEdit.customerId,
    offerToEdit.offeredTasks.length,
    orderToCreate.currencyId,
    orderToCreate.id,
    orderToCreate.officeId,
    selectedCustomer,
  ]);

  //Hook to update the dropdownOptions for customerUsers according to the selected customer and update paymentConditions to the default of a customer onChange
  useEffect(() => {
    if (orderToCreate.customerId === "") setAdditionalReceiverContacts([]);
    const customerPaymentCondition: PaymentCondition | undefined =
      loadedCustomers.find(
        (customer) => customer.id === orderToCreate.customerId
      )?.paymentCondition;
    if (customerPaymentCondition)
      setOrderToCreate({
        ...orderToCreate,
        paymentCondition: customerPaymentCondition,
      });
    const options: Option[] = loadedCustomerUsers
      .filter(
        (customerUser) => customerUser.customerId === orderToCreate.customerId
      )
      .map((customerUser) => ({
        label: `${customerUser.firstName} ${customerUser.lastName}`,
        value: customerUser.contact.mail,
      }));

    orderToCreate.additionalReceivers?.forEach((receiver) => {
      if (!options.some((option) => option.value === receiver))
        options.push({ label: receiver, value: receiver });
    });
    setAdditionalReceiverContacts(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderToCreate.customerId,
    orderToCreate.additionalReceivers,
    loadedCustomerUsers,
  ]);

  /**
   * Holds the avialable options for the customerContacts
   */
  const customerContacts: Option[] = useMemo((): Option[] => {
    if (!selectedCustomer) return [];
    const customerPaymentCondition: PaymentCondition | undefined =
      selectedCustomer?.paymentCondition;
    if (customerPaymentCondition)
      setOrderToCreate((prev) => ({
        ...prev,
        paymentCondition: customerPaymentCondition,
      }));

    return loadedCustomerUsers
      .filter((customerUser) => customerUser.customerId === selectedCustomer.id)
      .map((customerUser) => ({
        label: `${customerUser.firstName} ${customerUser.lastName}`,
        value: customerUser.id,
      }));
  }, [loadedCustomerUsers, selectedCustomer]);

  /**
   * DropdownOptions to choose a customer for a different invoiceRecipient
   */
  const customerDropdownOptions: Option[] = useMemo(
    (): Option[] =>
      generateDropdownOptionsForCustomers(
        filterValidCustomers(loadedCustomers)
      ),
    [loadedCustomers]
  );

  /**
   * Options for the customer on the workingLocation
   */
  const workingLocationCustomerOptions: Option[] = useMemo(
    (): Option[] => generateDropdownOptions(workingLocations, "name", "id"),
    [workingLocations]
  );

  /**
   * CustomerUsers for the workingLocation
   */
  const workingLocationContacts: CustomerUser[] = useMemo(
    (): CustomerUser[] =>
      loadedCustomerUsers.filter(
        (customerUser) =>
          customerUser.customerId === orderToCreate.workingLocation.customerId
      ),
    [loadedCustomerUsers, orderToCreate.workingLocation.customerId]
  );

  /**
   * DropdownOptions to choose a customerUser for a different invoiceRecipient
   */
  const customerUserDropdownOptions: Option[] = useMemo((): Option[] => {
    if (
      !orderToCreate?.invoiceRecipient?.customerId ||
      !orderToCreate.invoiceRecipient.customerLocationId
    )
      return [];
    const customerUsers: CustomerUser[] = loadedCustomerUsers.filter(
      (loadedUser) =>
        loadedUser.customerId === orderToCreate.invoiceRecipient.customerId &&
        loadedUser.locations.includes(
          orderToCreate.invoiceRecipient.customerLocationId
        )
    );
    return generateDropdownOptions(
      customerUsers,
      ["firstName", "lastName"],
      "id"
    );
  }, [
    loadedCustomerUsers,
    orderToCreate.invoiceRecipient.customerId,
    orderToCreate.invoiceRecipient.customerLocationId,
  ]);
  /**
   * Holds options for the workLocation and changes when the selected workingLocation changes
   */
  const workingLocationContactOptions: Option[] = useMemo(
    (): Option[] =>
      workingLocationContacts.map((contact) => ({
        label: contact.lastName + ", " + contact.firstName,
        value: contact.id,
      })),
    [workingLocationContacts]
  );

  /**
   * Helper to update all relevant locations after selecting the central customer
   *
   * @param customerId The selected customer id
   */
  const handleCentralCustomerSelection = (customerId: string): void => {
    const foundCustomer: Customer | undefined = loadedCustomers.find(
      (cust) => cust.id === customerId
    );
    if (foundCustomer?.softLocked) setSoftLockedCustomer(foundCustomer);
    if (!foundCustomer) return;
    setOrderToCreate({
      ...orderToCreate,
      customerId,
      invoiceRecipient: {
        customerUserId:
          loadedCustomerUsers
            .filter((customerUser) => customerUser.customerId === customerId)
            .find((customerUser) => customerUser.defaultInvoiceContact)?.id ||
          "",
        customerLocationId: "",
        customerId,
      },
      paymentCondition: foundCustomer.paymentCondition,
    });
    setOfferToEdit({
      ...offerToEdit,
      customerId,
    });
  };

  /**
   * Helper function to save a new order on submit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (orderToCreate.id) {
      updateOrderAndOffer(axios, offerToEdit, orderToCreate).then(
        (updatedObjects) => {
          if (updatedObjects?.order && updatedObjects?.offer && redirect)
            navigate("/offer");
          else if (updatedObjects) {
            setOfferToEdit(updatedObjects.offer);
            setOrderToCreate(updatedObjects.order);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.offerUpdate"),
            });
          }
        }
      );
    } else {
      createNewOrder(axios, orderToCreate).then((createdOrder) => {
        if (!createdOrder) return;
        createNewOffer(axios, {
          ...offerToEdit,
          orderId: createdOrder.id,
        }).then((offer) => {
          if (createProject) handleAcceptOffer(offer);
          else if (offer && redirect) navigate("/offer");
          else if (offer) {
            setOfferToEdit(offer);
            setMailConfig((old) => ({ ...old, offerId: offer.id }));
            setOrderToCreate(createdOrder);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.offerCreate"),
            });
          }
        });
      });
    }
  };

  /**
   * Holds the tasks and the customer specific articles as tasks
   */
  const availableTasks: Task[] = useMemo((): Task[] => {
    return [
      ...loadedTasks,
      ...customerArticles.map((article): Task => {
        const translation: Map<Language, string> = new Map<Language, string>();
        translation.set(offerToEdit.language, article.description || "");
        return {
          id: article.id,
          amount: 1,
          annotation: article.annotation || "",
          translations: translation,
          identifier: article.partnumber || "",
          price: article.price || 1,
          standard: false,
          title: article.partnumber || "",
          unit: article.unit || "",
          disabled: false,
          unitCode: article.unitCode || "",
        };
      }),
    ];
  }, [
    loadedTasks.length,
    customerArticles.length,
    offerToEdit.customerId,
    offerToEdit.language,
  ]);

  //Holds generated TableRows out of offeredTasks
  const rows: TableRow[] = useMemo((): TableRow[] => {
    if (
      (loadedTasks.length === 0 && customerArticles?.length === 0) ||
      !selectedCustomer
    )
      return [];
    return convertOfferedTasksIntoTableEntries(
      offerToEdit.offeredTasks,
      availableTasks,
      orderToCreate.officeId,
      orderToCreate.currencyId,
      selectedCustomer,
      offerToEdit.language,
      setOfferedTaskIdToDelete,
      setOfferToEdit,
      location.state?.isReadOnly
    );
  }, [
    offerToEdit,
    availableTasks.length,
    orderToCreate.officeId,
    orderToCreate.currencyId,
    location.state?.isReadOnly,
    loadedCustomers.length,
  ]);

  /**
   * Handler method to accept an offer
   * @param offer to accept
   */
  const handleAcceptOffer = async (offer?: Offer): Promise<void> => {
    return await acceptOffer(
      axios,
      offer
        ? {
            offerId: offer.id,
            language: offer.language,
            userId: user.id,
            offerBottom: false,
            offerTop: false,
            showSum: true,
          }
        : {
            offerId: offerToEdit.id,
            language: offerToEdit.language || Language.DE,
            userId: user.id,
            offerBottom: false,
            offerTop: false,
            showSum: true,
          }
    ).then((project) => {
      if (!project) return;

      //navigate to update the location state. then navigate to the next page
      navigate("/offer/create", {
        state: {
          offer: { ...offerToEdit, offerState: OfferState.OFFER_ACCEPTED },
        },
      });
      navigate("/project/edit", { state: { project } });
    });
  };

  /**
   * Handler method to decline an offer
   */
  const handleRejectOffer = (): Promise<void> =>
    rejectOffer(axios, offerToEdit.id).then((success) => {
      if (success) navigate("/offer");
    });

  /**
   * Helper method to create and download a document with the offer
   */
  const downloadOfferPDF = async (download: boolean): Promise<void> => {
    const document: Blob | undefined = await getOfferPDF(
      axios,
      offerToEdit.id,
      user.id,
      offerToEdit.language
    );
    if (!document) {
      console.error("document not set");
      return;
    }
    if (download)
      downloadFile(
        document,
        t("pages.order.create.offerFileName", {
          replace: { NUMBER: offerToEdit.numberRangeNumber },
        })
      );
    else setDocumentToShow(document);
  };

  /**
   * Helper method to send a offer to the customer
   */
  const handleSendOffer = async (): Promise<void> => {
    if (!offerToEdit.id) {
      const response: OrderWithOffer | undefined = await updateOrderAndOffer(
        axios,
        offerToEdit,
        orderToCreate
      );
      if (response?.offer && response?.order) {
        setOrderToCreate(response.order);
        sendNewOffer(axios, {
          ...mailConfig,
          offerId: response?.offer.id,
        }).then((updatedOffer) => {
          if (updatedOffer) {
            setOfferToEdit(updatedOffer);
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.sendOfferMail"),
            });
          }
          toggleMailPopup(false);
        });
      }
    } else
      sendNewOffer(axios, mailConfig).then((updatedOffer) => {
        if (updatedOffer) {
          setOfferToEdit(updatedOffer);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.sendOfferMail"),
          });
        }
        toggleMailPopup(false);
      });
  };
  /**
   * useMemo to check if an offer can be accepted or declined
   */
  const actionsAllowed: boolean = useMemo(
    (): boolean =>
      !!offerToEdit.id &&
      !location.state?.isReadOnly &&
      (offerToEdit.offerState === OfferState.OFFER_PENDING ||
        offerToEdit.offerState === OfferState.OFFER_PREP),

    [location.state?.isReadOnly, offerToEdit.id, offerToEdit.offerState]
  );

  /*
   * Helper method to find the selected paymentCondition and update the offer
   * @param id of the selected paymentCondition
   */
  const handlePaymentConditionChange = (id: string): void => {
    const condition: PaymentCondition | undefined =
      loadedPaymentConditions?.find((condition) => condition.id === id);
    if (condition)
      setOrderToCreate({ ...orderToCreate, paymentCondition: condition });
  };

  /**
   * Helper to fill some fields in respect of the given office id
   *
   * @param officeId The selected id of the office
   */
  const handleOfficeSelection = (officeId: string): void => {
    const selectedOffice: Office | undefined = loadedOffices.find(
      (office) => office.id === officeId
    );
    if (!selectedOffice) return; // TODO noti?
    setOrderToCreate({
      ...orderToCreate,
      officeId,
      companyId: selectedOffice.companyId,
    });
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      {documentToShow && (
        <Popup
          isOpen={!!documentToShow}
          onClose={() => setDocumentToShow(undefined)}
        >
          <object
            data={URL.createObjectURL(documentToShow)}
            type={documentToShow.type}
            width="1000px"
            height="700px"
          />
        </Popup>
      )}
      <TopBar
        title={
          createProject
            ? t("pages.order.create.topBarHeadlineProject")
            : offerToEdit.id
            ? t("pages.order.create.topBarHeadlineEdit")
            : t("pages.order.create.topBarHeadlineCreate")
        }
        onBackClick={() => navigate(-1)}
      >
        <MailPopup
          isOpen={showMailPopup}
          userId={user.id}
          referenceId={offerToEdit.id}
          mailConfig={mailConfig}
          sendMail={handleSendOffer}
          toggleOpen={toggleMailPopup}
          updateConfig={(updatedConfig) => {
            setMailConfig({
              ...mailConfig,
              ...(updatedConfig as OfferMailConfiguration),
            });
          }}
          sendDates={offerToEdit.sendDates}
        >
          <Checkbox
            isChecked={!!mailConfig.isOfferTop}
            onCheck={(isOfferTop) =>
              setMailConfig((old) => ({ ...old, isOfferTop }))
            }
            label={t("pages.office.edit.offerTop")}
          />
          <Checkbox
            isChecked={!!mailConfig.isOfferBottom}
            onCheck={(isOfferBottom) =>
              setMailConfig((old) => ({ ...old, isOfferBottom }))
            }
            label={t("pages.office.edit.offerBottom")}
          />
        </MailPopup>
        {actionsAllowed && (
          <>
            <Button
              type="button"
              value={t("general.buttons.accepted")}
              backgroundColor="#34b15e"
              onClick={handleAcceptOffer}
              disabled={location.state?.isReadOnly}
            />
            <Button
              type="button"
              value={t("general.buttons.declined")}
              backgroundColor="#BC2E46"
              onClick={handleRejectOffer}
              disabled={location.state?.isReadOnly}
            />
          </>
        )}
        {!location.state?.isReadOnly && (
          <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
        )}
      </TopBar>
      <SoftLockedPopup
        customer={softLockedCustomer}
        closePopup={() => setSoftLockedCustomer(undefined)}
      />
      <Popup
        isOpen={!!offeredTaskIdToDelete}
        onClose={() => setOfferedTaskIdToDelete(undefined)}
        title={t("pages.order.create.deleteTaskPopup")}
        buttons={[
          <Button
            backgroundColor="#BC2E46"
            value={t("general.buttons.yes")}
            onClick={() => {
              setOfferToEdit((prev) => ({
                ...prev,
                offeredTasks: offerToEdit.offeredTasks.filter(
                  (task) => task.id !== offeredTaskIdToDelete
                ),
              }));
              setOfferedTaskIdToDelete(undefined);
            }}
          />,
          <Button
            value={t("general.buttons.no")}
            onClick={() => setOfferedTaskIdToDelete(undefined)}
          />,
        ]}
      >
        <p>{t("pages.order.create.deleteTask")}</p>
      </Popup>
      <Box>
        <Input
          onChange={() => {}}
          type="text"
          disabled
          value={offerToEdit.numberRangeNumber || "-"}
          label={t("pages.order.create.numberRange")}
        />
        <fieldset className="three-columns">
          <legend>{t("pages.order.create.generalInformation")}</legend>
          <Dropdown
            disabled={location.state?.isReadOnly}
            selectedOption={orderToCreate.officeId}
            onChange={handleOfficeSelection}
            label={t("pages.order.create.office")}
            options={loadedOffices.map((office) => ({
              label: office.name,
              value: office.id,
            }))}
          />
          <Dropdown
            disabled={location.state?.isReadOnly}
            label={t("pages.order.create.currency")}
            selectedOption={orderToCreate.currencyId}
            onChange={(currencyId) =>
              setOrderToCreate({ ...orderToCreate, currencyId })
            }
            options={generateDropdownOptions(loadedCurrencys, "title", "id")}
          />
          <Dropdown
            disabled={location.state?.isReadOnly}
            options={Object.values(OfferProvidedBy)
              .filter(
                (providedBy) => providedBy !== OfferProvidedBy.CUSTOMER_PROJECT
              )
              .map((providedBy) => ({
                label: t(`pages.order.create.providedBy.${providedBy}`),
                value: providedBy,
              }))}
            label={t("pages.order.create.providedBy.label")}
            selectedOption={offerToEdit.providedBy}
            onChange={(providedBy) =>
              setOfferToEdit({
                ...offerToEdit,
                providedBy: providedBy as OfferProvidedBy,
              })
            }
          />
          <Dropdown
            disabled={location.state?.isReadOnly}
            label={t("pages.order.create.language")}
            selectedOption={offerToEdit.language}
            onChange={(language) => {
              setOfferToEdit({
                ...offerToEdit,
                language: language as Language,
              });
              setMailConfig((old) => ({
                ...old,
                language: language as Language,
              }));
            }}
            options={generateDropdownOptionsForLanguage()}
          />
          <Dropdown
            disabled={location.state?.isReadOnly}
            options={companyDropdownOptions}
            selectedOption={orderToCreate.companyId}
            onChange={(companyId) =>
              setOrderToCreate({ ...orderToCreate, companyId })
            }
            label={t("pages.order.create.company")}
            required
          />
          <Dropdown
            onChange={(businessAreaId) =>
              setOrderToCreate({
                ...orderToCreate,
                businessAreaId,
              })
            }
            options={businessAreaOptions}
            label={t("pages.order.create.businessArea")}
            selectedOption={orderToCreate.businessAreaId}
            disabled={location.state?.isReadOnly || !orderToCreate.companyId}
            required
          />
        </fieldset>
      </Box>
      <Box>
        <fieldset className="three-columns">
          <legend>{t("pages.order.create.customerInformation")}</legend>
          <Dropdown
            required
            disabled={location.state?.isReadOnly}
            onChange={handleCentralCustomerSelection}
            selectedOption={orderToCreate.customerId}
            options={filterValidCustomers(loadedCustomers).map((customer) => ({
              label: customer.name,
              value: customer.id,
            }))}
            label={t("pages.order.create.customer")}
          />
          <Dropdown
            required
            disabled={location.state?.isReadOnly}
            onChange={(locationId) => {
              setOrderToCreate({ ...orderToCreate, locationId });
            }}
            selectedOption={orderToCreate.locationId}
            options={customerLocationOptions}
            label={t("pages.order.create.customerLocation")}
          />
          <Dropdown
            required
            disabled={location.state?.isReadOnly}
            selectedOption={orderToCreate.customerContact}
            options={customerContacts}
            label={t("pages.order.create.contactPerson")}
            onChange={(customerContact) =>
              setOrderToCreate({ ...orderToCreate, customerContact })
            }
          />
          <Dropdown
            required
            label={t("pages.order.create.workingLocationCustomer")}
            options={customerDropdownOptions}
            onChange={(customerId) =>
              setOrderToCreate({
                ...orderToCreate,
                workingLocation: {
                  customerId,
                  customerLocationId: "",
                  customerContactId: "",
                },
              })
            }
            selectedOption={orderToCreate?.workingLocation?.customerId || ""}
          />
          <Dropdown
            required
            label={t("pages.order.create.workingLocation")}
            options={workingLocationCustomerOptions}
            onChange={(customerLocationId) =>
              setOrderToCreate({
                ...orderToCreate,
                workingLocation: {
                  ...orderToCreate.workingLocation,
                  customerLocationId,
                },
              })
            }
            selectedOption={
              orderToCreate?.workingLocation?.customerLocationId || ""
            }
          />
          <Dropdown
            required
            options={workingLocationContactOptions}
            label={t("pages.order.create.workingLocationContact")}
            onChange={(selectedUserId) =>
              setOrderToCreate({
                ...orderToCreate,
                workingLocation: {
                  ...orderToCreate.workingLocation,
                  customerContactId: selectedUserId,
                },
              })
            }
            selectedOption={orderToCreate.workingLocation.customerContactId}
          />
          <Dropdown
            required
            selectedOption={orderToCreate.invoiceRecipient?.customerId}
            label={t("pages.order.create.invoiceRecipient")}
            options={customerDropdownOptions}
            onChange={(customerId) =>
              setOrderToCreate({
                ...orderToCreate,
                invoiceRecipient: {
                  customerUserId: "",
                  customerLocationId: "",
                  customerId,
                },
              })
            }
          />
          <Dropdown
            required
            selectedOption={orderToCreate.invoiceRecipient?.customerLocationId}
            label={t("pages.project.edit.invoiceRecipientLocation")}
            options={invoiceLocationOptions}
            onChange={(customerLocationId) =>
              setOrderToCreate({
                ...orderToCreate,
                invoiceRecipient: {
                  customerId: orderToCreate.invoiceRecipient.customerId,
                  customerLocationId,
                  customerUserId: "",
                },
              })
            }
          />
          <Dropdown
            required
            disabled={!orderToCreate.invoiceRecipient?.customerId}
            selectedOption={orderToCreate.invoiceRecipient?.customerUserId}
            label={t("pages.order.create.invoiceContact")}
            options={customerUserDropdownOptions}
            onChange={(customerUserId) => {
              if (orderToCreate.invoiceRecipient)
                setOrderToCreate({
                  ...orderToCreate,
                  invoiceRecipient: {
                    ...orderToCreate.invoiceRecipient,
                    customerUserId,
                  },
                });
            }}
          />
          <Dropdown
            required
            options={paymentOptions}
            selectedOption={orderToCreate.paymentCondition?.id}
            label={t("pages.order.create.paymentCondition")}
            onChange={handlePaymentConditionChange}
          />
          <TextArea
            label={t("pages.order.create.workingLocationInformation")}
            onChange={(workingLocationComment) =>
              setOrderToCreate({ ...orderToCreate, workingLocationComment })
            }
            value={orderToCreate.workingLocationComment}
            resizable
          />

          <Dropdown
            disabled={location.state?.isReadOnly}
            selectedMultiOptions={orderToCreate.additionalReceivers || []}
            options={additionalReceiverContacts}
            label={t("pages.order.create.addReceiver")}
            onChangeMultiple={(additionalReceivers) => {
              setOrderToCreate({ ...orderToCreate, additionalReceivers });
              setMailConfig((old) => ({
                ...old,
                receiverCC: additionalReceivers,
              }));
            }}
            multi
            create
            onCreateNew={(newReceiver) =>
              setOrderToCreate({
                ...orderToCreate,
                additionalReceivers: [
                  ...(orderToCreate.additionalReceivers
                    ? [...orderToCreate.additionalReceivers, newReceiver.value]
                    : newReceiver.value),
                ],
              })
            }
          />
        </fieldset>
      </Box>
      <Box>
        <fieldset>
          <legend>{t("pages.order.create.orderDescription")}</legend>
          <Input
            disabled={location.state?.isReadOnly}
            type="text"
            value={offerToEdit.annotation}
            onChange={(annotation) =>
              setOfferToEdit({ ...offerToEdit, annotation })
            }
            label={t("pages.order.create.description")}
          />
          <div className="three-columns">
            <Input
              onChange={(customerArticleNumber) =>
                setOrderToCreate({ ...orderToCreate, customerArticleNumber })
              }
              type="text"
              value={orderToCreate.customerArticleNumber}
              label={t("pages.order.create.customerArticleNumber")}
            />
            <Input
              onChange={(customerArticleDescription) =>
                setOrderToCreate({
                  ...orderToCreate,
                  customerArticleDescription,
                })
              }
              type="text"
              value={orderToCreate.customerArticleDescription}
              label={t("pages.order.create.customerArticleDescription")}
            />
            <Input
              disabled={location.state?.isReadOnly}
              label={t("pages.order.create.amountPersonal")}
              type="number"
              value={offerToEdit.plannedAmountWorker}
              onChangeNumber={(plannedAmountWorker) =>
                setOfferToEdit({
                  ...offerToEdit,
                  plannedAmountWorker: plannedAmountWorker,
                })
              }
            />
            <Input
              disabled={location.state?.isReadOnly}
              value={orderToCreate.pieces}
              type="number"
              onChangeNumber={(pieces) =>
                setOrderToCreate({ ...orderToCreate, pieces })
              }
              label={t("pages.order.create.pieces")}
            />
            <Dropdown
              selectedOption={orderToCreate.invoiceType}
              onChange={(projectInvoiceType) =>
                setOrderToCreate({
                  ...orderToCreate,
                  invoiceType: projectInvoiceType as ProjectInvoiceType,
                })
              }
              label={t("pages.order.create.invoiceType")}
              options={Object.values(ProjectInvoiceType).map((type) => ({
                label: t(`general.projectInvoiceType.${type}`),
                value: type,
              }))}
            />
            <Checkbox
              isChecked={orderToCreate.standardPriced}
              onCheck={(standardPriced) =>
                setOrderToCreate({ ...orderToCreate, standardPriced })
              }
              label={t("pages.order.create.standardPriced")}
            />
          </div>
          <div>
            <div className="order-create--options-wrapper">
              {!location.state?.isReadOnly && (
                <>
                  {!orderToCreate.standardPriced && (
                    <AddIcon
                      title={t("general.icons.add")}
                      width={30}
                      onClick={() =>
                        setOfferToEdit({
                          ...offerToEdit,
                          offeredTasks: [
                            ...offerToEdit.offeredTasks,
                            generateEmptyOfferedTask({
                              index: offerToEdit.offeredTasks.length + 1,
                            }),
                          ],
                        })
                      }
                    />
                  )}
                  <div>
                    <Checkbox
                      label={t("pages.order.create.offerTop")}
                      isChecked={offerToEdit.topInfo}
                      onCheck={(topInfo) =>
                        setOfferToEdit({
                          ...offerToEdit,
                          topInfo,
                        })
                      }
                    />
                    <Checkbox
                      label={t("pages.order.create.offerBottom")}
                      isChecked={offerToEdit.bottomInfo}
                      onCheck={(bottomInfo) =>
                        setOfferToEdit({
                          ...offerToEdit,
                          bottomInfo,
                        })
                      }
                    />
                    <Checkbox
                      label={t("pages.order.create.showSum")}
                      isChecked={offerToEdit.showSum}
                      onCheck={(showSum) =>
                        setOfferToEdit({ ...offerToEdit, showSum })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            {!orderToCreate.standardPriced && rows.length > 0 && (
              <Table
                rows={rows}
                header={
                  t("pages.order.create.tableHeader", {
                    returnObjects: true,
                  }) as TableHeader[]
                }
              />
            )}
          </div>
        </fieldset>
      </Box>
      {!createProject && offerToEdit.id && (
        <Box>
          <div className="three-columns">
            <Button
              value={t("general.buttons.documentPreview")}
              type="button"
              onClick={() => downloadOfferPDF(false)}
            />
            <Button
              value={t("general.buttons.download")}
              type="button"
              onClick={() => downloadOfferPDF(true)}
            />
            <Button
              value={t("general.buttons.sendOffer")}
              onClick={() => {
                setMailConfig((old) => ({ ...old, type: MailType.OFFER }));
                toggleMailPopup(true);
              }}
            />
          </div>
        </Box>
      )}
      {offerToEdit.customerId && offerToEdit.orderId && offerToEdit.id && (
        <FileOverview
          path={`${offerToEdit.customerId}/${offerToEdit.orderId}/${offerToEdit.id}/`}
          bucket={DocumentBucket.OFFER}
          customerId={offerToEdit.customerId}
          userId={user.id}
          orderId={offerToEdit.orderId}
        />
      )}
    </form>
  );
};
