import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { Language } from "../Language/Language.types";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { TimeTracking } from "./Timetracking.types";

/**
 * API Method to load timeTracking object for specific user and day
 * @param userId of the user to load the tracking for
 * @param date of the tracking to search for, default value is today
 * @param axios instance of axios
 * @returns TimeTracking if found, undefined if not
 */
export const getTimeTrackingForUserAndDay = async (
  axios: AxiosInstance,
  userId: string,
  date: Date = new Date()
): Promise<TimeTracking | undefined> => {
  return axios
    .get("/time/user/day", {
      params: { id: userId, date },
    })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getTimeTrackingDay"),
      });
      console.error("Error while loading timeTrackings for user", exc);
      return undefined;
    });
};

/**
 * API Method to create new TimeTracking on the MongoDB
 * @param axios instance of axios
 * @param userId of the user who creates the timeTracking
 * @returns created TimeTracking if successful, undefined if not
 */
export const createNewTimeTracking = async (
  axios: AxiosInstance,
  tracking: TimeTracking
): Promise<TimeTracking | undefined> => {
  return axios
    .post("/time/", tracking)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createTimeTracking"),
      });
      console.error("Error while saving timeTracking", exc);
      return undefined;
    });
};

/**
 * API Method to save timeTracking on the DB if its finished
 * @param axios instance of axios
 * @param timeTracking to update
 * @returns boolean if successful or not
 */
export const endAndSaveTimeTracking = async (
  axios: AxiosInstance,
  timeTracking: TimeTracking
): Promise<boolean> => {
  return axios
    .post("/time/update", timeTracking)
    .then((res) => res.status === 200)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.saveTimeTracking"),
      });
      console.error("Error while saving TimeTracking", exc);
      return false;
    });
};

/**
 * API method to load all timeTrackings for a user id
 * @param axios instance of axios
 * @param id of the user to load the timeTrackings for
 * @returns  Array of timeTrackings
 */
export const getAllTimeTrackingsByUserId = async (
  axios: AxiosInstance,
  id: string
): Promise<TimeTracking[]> => {
  return axios
    .get("/time/user", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllTimeTrackingsForUser"),
      });
      console.error("Error while loading timeTrackings for user", exc);
      return [];
    });
};

/**
 * API method to create a new TimeSheet for a given month
 * @param axios instance of axios
 * @param userId id of the user to get the timesheet for
 * @param selectedMonthAndYear to create the timeSheet for
 * @returns Blob of the created pdf
 */
export const getTimeSheetForMonth = async (
  axios: AxiosInstance,
  userId: string,
  selectedMonthAndYear: [number, number],
  language: Language
): Promise<Blob> => {
  return axios
    .get("/export/pdf/zeitkarte", {
      params: {
        month: selectedMonthAndYear[0],
        year: selectedMonthAndYear[1],
        userId,
        language,
      },
      responseType: "blob",
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading timecard", exc);
      return undefined;
    });
};

/**
 * API method to update a timeTracking
 * @param axios instance of axios
 * @param updatedTracking updated timeTracking
 * @returns  updated tracking or undefined
 */
export const updateTimeTracking = async (
  axios: AxiosInstance,
  updatedTracking: TimeTracking
): Promise<TimeTracking | undefined> => {
  return axios
    .post("/time/update", updatedTracking)
    .then((res) => {
      generateNotification({
        type: NotificationType.SUCCESS,
        value: i18n.t("general.notification.success.updateTimeTracking"),
      });
      return res.data;
    })
    .catch((exc) => {
      console.error("Error while updating timetracking", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notificaion.error.updateTimetracking"),
      });
      return undefined;
    });
};

/**
 * API Method to delete a timeTracking
 * @param axios instance of axios
 * @param trackingId id of the tracking to delete
 * @returns booleam depending on success
 */
export const deleteTimeTracking = async (
  axios: AxiosInstance,
  trackingId: string
): Promise<boolean> => {
  return axios
    .post("/time/delete", trackingId, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error while deleting tracking", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.deleteTimeTracking"),
      });
      return false;
    });
};
