import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { Currency, CurrencyConversion } from "./Currency.types";

/**
 * API Method to get all currencys
 * @param axios instance of axios
 * @returns all found currencys
 */
export const getAllCurrencies = async (
  axios: AxiosInstance
): Promise<Currency[]> => {
  return axios
    .get("currency/all")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCurrencies"),
      });
      console.error("Error while loading currencys", exc);
      return [];
    });
};

/**
 * API Method to get all active currencys
 * @param axios instance of axios
 * @returns all found currencys
 */
export const getAllActiveCurrencies = async (
  axios: AxiosInstance
): Promise<Currency[]> => {
  return axios
    .get("currency/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getAllCurrencies"),
      });
      console.error("Error while loading currencys", exc);
      return [];
    });
};

/**
 * API Method to update a Currency
 * @param axios instance of axios
 * @param currency updated currency object
 * @returns updated Currency or undefined on error
 */
export const updateCurrency = async (
  axios: AxiosInstance,
  currency: Currency
): Promise<Currency | undefined> => {
  return axios
    .post("currency/update", currency)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewCurrency"),
      });
      console.error("Error while updating currency", exc);
      return undefined;
    });
};

/**
 * API Method to create a new Currency
 * @param axios instance of axios
 * @param currency new currency to save
 * @returns  created Currency or undefined on Error
 */
export const createNewCurrency = async (
  axios: AxiosInstance,
  currency: Currency
): Promise<Currency | undefined> => {
  return axios
    .post("/currency/", currency)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewCurrency"),
      });
      console.error("Error while creating currency", exc);
      return undefined;
    });
};

/**
 * API Method to get all existing currencyConversions for a currency
 * @param axios instance of axios
 * @param currencyId id of the currency to load the conversions for
 * @returns Array of all found conversions
 */
export const getAllConversionsForCurrency = async (
  axios: AxiosInstance,
  currencyId: string
): Promise<CurrencyConversion[]> => {
  return axios
    .get("currency/conversion/all", { params: { currencyId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading converions for currency", exc);
      return [];
    });
};

/**
 * API Method to load all active currencyConversions
 * @param axios instance of axios
 * @returns the active currencyConversion of all currencies
 */
export const getAllActiveCurrencyConversions = async (
  axios: AxiosInstance
): Promise<CurrencyConversion[]> => {
  return axios
    .get("currency/conversion/all/active")
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading converions for currency", exc);
      return [];
    });
};

/**
 * API Method to get a currency by its it
 * @param axios instance of axios
 * @param id id of the currency to load
 * @returns found currency or undefined
 */
export const getCurrencyById = async (
  axios: AxiosInstance,
  id: string
): Promise<Currency | undefined> => {
  return axios
    .get("currency/", { params: { id } })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getCurrencyById"),
      });
      console.error("Error while loading currency", exc);
      return undefined;
    });
};

/**
 * API Method to create a new currencyConversion
 * @param axios instance of axios
 * @param conversion converssion to create
 * @returns Promise containing the saved conversion or undefined on Error
 */
export const createNewCurrencyConversion = async (
  axios: AxiosInstance,
  conversion: CurrencyConversion
): Promise<CurrencyConversion | undefined> => {
  return axios
    .post("currency/conversion/", conversion)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while saving currencyConversion", exc);
      return undefined;
    });
};
