import {
  Checkbox,
  Dropdown,
  Input,
  Option,
  TableRow,
  TextArea,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router-dom";
import {
  addOrRemove,
  Customer,
  CustomerArticle,
  CustomerUser,
  generateDropdownOptions,
  Order,
  ProjectCheckResult,
  ProjectProtocol,
  ProjectTimeBooking,
  ProtocolDocumentConfig,
  SimpleUser,
} from "shared";
import {
  CustomerLocation,
  ShiftType,
} from "shared/src/customerLocation/CustomerLocation.types";
import {
  AppointmentState,
  ErrorPattern,
  Project,
  ProjectState,
  ProtocolMailingSettings,
  Schedule,
  ScheduleAppointmentDetail,
  ScheduleType,
  WorkInstruction,
} from "shared/src/project/Project.types";
import { uid } from "uid";
import { ReactComponent as DescriptionIcon } from "../../assets/addDocument.svg";
import { ReactComponent as TrashIcon } from "../../assets/delete.svg";
import { ReactComponent as CarIcon } from "../../assets/delivery.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as ProtocolIcon } from "../../assets/protocol.svg";
import i18n from "../../i18n/i18n";
import { getUserNameForSimpleUser } from "../user/User.utils";
import dayjs from "shared/src/tools/Dayjs";
import { generateEmptyCustomerArticle } from "../CustomerArticle.utils";

/**
 * Util method to convert projects into tableEntries
 * @param projects Array that contains the projects to display
 * @param customers Array of customers to display the names in the tableRows
 * @returns Array of tableRows
 */
export const convertProjectsIntoTableEntries = (
  projects: Project[],
  customers: Customer[],
  customerLocations: CustomerLocation[],
  customerUsers: CustomerUser[],
  orders: Order[],
  navigate: NavigateFunction,
  handleDownload: (projectId: string) => void
): TableRow[] => {
  return projects.map((project) => {
    const order: Order | undefined = orders.find(
      (order) => order.id === project.orderId
    );
    const location: string =
      customerLocations.find((location) => location.id === order?.locationId)
        ?.name || "-";

    const locationContact: CustomerUser | undefined = customerUsers.find(
      (contact) => contact.id === order?.locationContactId
    );

    const workingLocation: CustomerLocation | undefined =
      customerLocations.find(
        (location) => location.id === order?.workingLocation.customerLocationId
      );

    const workLocationCustomer: string =
      customers.find(
        (customer) => customer.id === order?.workingLocation.customerId
      )?.name || "-";

    const workLocationContact: CustomerUser | undefined = customerUsers.find(
      (contact) => contact.id === order?.workingLocation.customerContactId
    );
    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";
    const invoiceRecipientContact: CustomerUser | undefined =
      customerUsers.find(
        (customerUser) => customerUser.id === order?.invoiceRecipient.customerId
      );
    const customer: string =
      customers.find((customer) => customer.id === order?.customerId)?.name ||
      "-";

    return {
      id: project.id,
      onClick: () => navigate("/project/edit", { state: { project } }),
      content: [
        project.numberRangeNumber,
        order?.customerOrderNumber || "-",
        ` ${project.startDate?.toLocaleDateString("DE-de")} -
        ${project.endDate?.toLocaleDateString("DE-de") ?? "open"}`,
        customer,
        location,
        (locationContact?.firstName || "-") +
          " " +
          (locationContact?.lastName || "-"),
        workLocationCustomer,
        workingLocation?.name || "-",
        (workLocationContact?.firstName || "-") +
          " " +
          (workLocationContact?.lastName || "-"),
        invoiceRecipient,
        invoiceRecipientContact?.firstName ||
          "-" + " " + invoiceRecipientContact?.lastName ||
          "-",
        order?.customerArticleNumber || "-",
        order?.customerArticleDescription || "-",
        project.annotation,
        project.projectState === ProjectState.PROJECT_COMPLETED
          ? i18n.t("pages.project.overview.completed")
          : i18n.t("pages.project.overview.notCompleted"),
        <div className="table-action__icon-wrapper">
          <EditIcon
            title={i18n.t("general.icons.edit")}
            onClick={(evt) => {
              evt.stopPropagation();
              navigate("/project/edit", { state: { project } });
            }}
          />
          <ProtocolIcon
            onClick={(evt) => {
              evt.stopPropagation();
              navigate("/project/protocol", { state: { project } });
            }}
          />
          <CarIcon
            onClick={(evt) => {
              evt.stopPropagation();
              navigate("/delivery", { state: { project } });
            }}
          />
          <DescriptionIcon
            onClick={(evt) => {
              evt.stopPropagation();
              handleDownload(project.id);
            }}
          />
        </div>,
      ],
    };
  });
};

/**
 * Util method to convert projects into tableEntries
 * @param projects Array that contains the projects to display
 * @param customers Array of customers to display the names in the tableRows
 * @returns Array of tableRows
 */
export const convertProjectsIntoTableEntriesForProtocols = (
  projects: Project[],
  customers: Customer[],
  customerLocations: CustomerLocation[],
  customerUsers: CustomerUser[],
  orders: Order[],
  setSelectedProjects: Dispatch<SetStateAction<string[]>>,
  selectedProjects: string[]
): TableRow[] => {
  return projects.map((project) => {
    const order: Order | undefined = orders.find(
      (order) => order.id === project.orderId
    );
    const location: string =
      customerLocations.find((location) => location.id === order?.locationId)
        ?.name || "-";

    const locationContact: CustomerUser | undefined = customerUsers.find(
      (contact) => contact.id === order?.locationContactId
    );

    const workingLocation: CustomerLocation | undefined =
      customerLocations.find(
        (location) => location.id === order?.workingLocation.customerLocationId
      );

    const workLocationCustomer: string =
      customers.find(
        (customer) => customer.id === order?.workingLocation.customerId
      )?.name || "-";

    const workLocationContact: CustomerUser | undefined = customerUsers.find(
      (contact) => contact.id === order?.workingLocation.customerContactId
    );
    const invoiceRecipient: string =
      customers.find(
        (customer) => customer.id === order?.invoiceRecipient.customerId
      )?.name || "-";
    const invoiceRecipientContact: CustomerUser | undefined =
      customerUsers.find(
        (customerUser) => customerUser.id === order?.invoiceRecipient.customerId
      );
    const customer: string =
      customers.find((customer) => customer.id === order?.customerId)?.name ||
      "-";

    return {
      id: project.id,
      content: [
        <Checkbox
          onCheck={() =>
            setSelectedProjects((prev) => addOrRemove(prev, project.id))
          }
          isChecked={selectedProjects.includes(project.id)}
        />,
        project.numberRangeNumber,
        order?.customerOrderNumber || "-",
        ` ${project.startDate?.toLocaleDateString("DE-de")} -
        ${project.endDate?.toLocaleDateString("DE-de") ?? "open"}`,
        customer,
        location,
        (locationContact?.firstName || "-") +
          " " +
          (locationContact?.lastName || "-"),
        workLocationCustomer,
        workingLocation?.name || "-",
        (workLocationContact?.firstName || "-") +
          " " +
          (workLocationContact?.lastName || "-"),
        invoiceRecipient,
        invoiceRecipientContact?.firstName ||
          "-" + " " + invoiceRecipientContact?.lastName ||
          "-",
        order?.customerArticleNumber || "-",
        order?.customerArticleDescription || "-",
        project.annotation,
        project.projectState === ProjectState.PROJECT_COMPLETED
          ? i18n.t("pages.project.overview.completed")
          : i18n.t("pages.project.overview.notCompleted"),
        order?.lastProtocolDate?.toLocaleDateString("DE-de") || "-",
        order?.protocolSendDates && order.protocolSendDates.length < 1
          ? order.protocolSendDates
              ?.sort((a, b) => (dayjs(a).isBefore(b) ? 1 : -1))[0]
              ?.toLocaleDateString("DE-de")
          : "-",
      ],
    };
  });
};

/**
 * Util method to convert workInstructions into tableRows
 * @param instructions to convert
 * @returns Array of TableRows
 */
export const convertWorkInstructionsIntoTableRows = (
  project: Project,
  navigate: NavigateFunction
): TableRow[] =>
  project.instructions
    .sort((a, b) => a.index - b.index)
    .map((instruction) => ({
      id: instruction.id,
      content: [
        <div
          className="clickable"
          onClick={() =>
            navigate("/project/instruction", {
              state: { project, instruction },
            })
          }
        >
          {instruction.content}
        </div>,
      ],
    }));

/**
 * Util method to convert errorPatterns into TableRows
 * @param patterns errorPatterns to display
 * @param setProject Dispatch acion to update the project
 * @param disabled boolean if the content should be editable or not
 * @returns Array of TableRows
 */
export const convertErrorPatternsIntoTableRows = (
  patterns: ErrorPattern[],
  setProject: Dispatch<SetStateAction<Project | undefined>> = () => {},
  disabled?: boolean
): TableRow[] => {
  const letters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return patterns.map((pattern, index) => ({
    id: pattern.id,
    content: [
      letters[index],
      <div className="project-edit__error-patterns__icon-wrapper">
        <Input
          value={pattern.content}
          type="text"
          disabled={disabled}
          onChange={(content) =>
            setProject(
              (prevValue) =>
                prevValue && {
                  ...prevValue,
                  errorPatterns: prevValue.errorPatterns.map(
                    (existingpattern) =>
                      existingpattern.id === pattern.id
                        ? { ...pattern, content }
                        : existingpattern
                  ),
                }
            )
          }
        />
        {setProject && !disabled && (
          <TrashIcon
            className="project-edit__error-patterns__icon-wrapper__delete-icon"
            onClick={() =>
              setProject(
                (prevValue) =>
                  prevValue && {
                    ...prevValue,
                    errorPatterns: prevValue.errorPatterns.filter(
                      (existingpattern) => existingpattern.id !== pattern.id
                    ),
                  }
              )
            }
          />
        )}
      </div>,
    ],
  }));
};
/**
 * Util method to generate a new workInstruction
 * @param override setter to adjust the created object
 * @returns generated WorkInstruction
 */
export const generateNewWorkInstruction = (
  override?: Partial<WorkInstruction>
): WorkInstruction => ({
  content: "",
  createDate: new Date(),
  createdBy: "",
  disabled: false,
  id: uid(),
  index: 0,
  projectId: "",
  instructionTimeStamp: undefined,
  instructionBy: "",
  instructionFrom: "",
  ...override,
});
/**
 * Util method to generate a new errorPattern
 * @param override setter to adjust the created object
 * @returns generated ErrorPattern
 */
export const generateNewErrorPattern = (
  override?: Partial<ErrorPattern>
): ErrorPattern => ({
  content: "",
  createDate: new Date(),
  createdBy: "",
  disabled: false,
  id: uid(),
  index: 0,
  projectId: "",
  ...override,
});

/**
 * Util method to generate dropdown options for shiftType
 * @returns array of dropdown options
 */
export const generateDropdownOptionsForShift = (
  shiftsToDisplay?: ShiftType[]
): Option[] =>
  Object.values(ShiftType)
    .filter((type) => !shiftsToDisplay || shiftsToDisplay.includes(type))
    .map((shiftType) => ({
      label: i18n.t(`general.shiftType.${shiftType}`),
      value: shiftType,
    }));

/**
 * Util method to generate an empty projectProtocol
 * @param override partial of projectProtocol to adjust generated object
 * @returns generated projectProtocol
 */
export const generateEmptyProjectProtocol = (
  override?: Partial<ProjectProtocol>
): ProjectProtocol => ({
  checkResults: [],
  createDate: new Date(),
  createdBy: "",
  customerId: "",
  id: "",
  orderId: "",
  projectId: "",
  protocolDate: new Date(),
  shift: ShiftType.EARLY,
  timeBookings: [],
  officeId: "",
  ...override,
});

/**
 * Util method to generate an empty projectCheckResult
 * @param override partial of projectCheckResult to adjust generated object
 * @returns generated projectCheckResult
 */
export const generateEmptyProjectCheckResult = (
  override?: Partial<ProjectCheckResult>
): ProjectCheckResult => ({
  articleNumber: "",
  checkedTotal: 0,
  foundErrorPatterns: new Map<string, number>(),
  id: uid(),
  io: 0,
  nio: 0,
  note: "",
  rework: 0,
  totalIoAfterRework: 0,
  ...override,
});

/**
 * Util method to convert timeBookoings into tableRows
 * @param timeBookings Array of all timeBookings to display
 * @param users Array of SimpleUsers
 * @param updateTimeBookings method to update the content of the fields onChange
 * @returns Array of TableRows
 */
export const convertProjectTimeBookingsIntoTableEntries = (
  timeBookings: ProjectTimeBooking[],
  users: SimpleUser[],
  updateTimeBookings: (
    updatedBooking: ProjectTimeBooking,
    remove?: boolean
  ) => void
): TableRow[] => {
  return timeBookings.map((booking) => ({
    id: booking.id,
    content: [
      getUserNameForSimpleUser(booking.userId, users),
      <Input
        value={booking.startTime}
        type="time"
        onChangeTime={(startTime) =>
          updateTimeBookings({ ...booking, startTime })
        }
      />,
      <Input
        value={booking.endTime}
        type="time"
        onChangeTime={(endTime) => updateTimeBookings({ ...booking, endTime })}
      />,
      <Input
        value={booking.customerDrivingTime}
        type="number"
        onChangeNumber={(customerDrivingTime) =>
          updateTimeBookings({ ...booking, customerDrivingTime })
        }
      />,
      <Input
        value={booking.breakTime}
        type="number"
        onChangeNumber={(breakTime) =>
          updateTimeBookings({ ...booking, breakTime })
        }
      />,
      <Checkbox
        isChecked={booking.customerArrival}
        onCheck={(customerArrival) =>
          updateTimeBookings({ ...booking, customerArrival })
        }
      />,
      <Checkbox
        isChecked={booking.customerDeparture}
        onCheck={(customerDeparture) =>
          updateTimeBookings({ ...booking, customerDeparture })
        }
      />,
      <Input
        value={booking.userDrivingTime}
        type="number"
        onChangeNumber={(userDrivingTime) =>
          updateTimeBookings({ ...booking, userDrivingTime })
        }
      />,
      <Checkbox
        isChecked={booking.userArrival}
        onCheck={(userArrival) =>
          updateTimeBookings({ ...booking, userArrival })
        }
      />,
      <Checkbox
        isChecked={booking.userDeparture}
        onCheck={(userDeparture) =>
          updateTimeBookings({ ...booking, userDeparture })
        }
      />,
      <Input
        value={booking.breakTimeUser}
        type="number"
        onChangeNumber={(breakTimeUser) =>
          updateTimeBookings({ ...booking, breakTimeUser })
        }
      />,
      <div className="table-action__icon-wrapper">
        <TrashIcon
          className="delete"
          onClick={(evt) => {
            evt.stopPropagation();
            updateTimeBookings(booking, true);
          }}
        />
      </div>,
    ],
  }));
};

/**
 * Util method to convert CheckResults To TableEntries
 * @param results ProjectCheckResults to convert
 * @param updateCheckResults method to update the results onChange
 * @param errorPatterns array of relevant errorPatterns for the project
 * @returns Array of TableRows
 */
export const convertCheckResultsToTableEntries = (
  results: ProjectCheckResult[],
  updateCheckResults: (
    updatedResult: ProjectCheckResult,
    deleteEntry?: boolean
  ) => void,
  errorPatterns: ErrorPattern[],
  customerArticles: CustomerArticle[],
  checkPlausibility: (id: string) => void
): TableRow[] => {
  const availableArticles: CustomerArticle[] = [...customerArticles];
  results.forEach((result) => {
    if (
      customerArticles.some(
        (article) => article.partnumber === result.articleNumber
      )
    )
      return;
    else if (result.articleNumber !== "")
      availableArticles.push(
        generateEmptyCustomerArticle({ partnumber: result.articleNumber })
      );
  });
  return results.map((result) => ({
    id: result.id,
    content: [
      <Dropdown
        minWidth="150px"
        selectedOption={result.articleNumber}
        onCreateNew={(value: Option) => {
          checkPlausibility(result.id);
          updateCheckResults({ ...result, articleNumber: value.value });
        }}
        options={generateDropdownOptions(
          availableArticles,
          "partnumber",
          "partnumber"
        )}
        create
        onChange={(articleNumber) =>
          updateCheckResults({ ...result, articleNumber })
        }
      />,
      <TextArea
        resizable
        onChange={(note) => updateCheckResults({ ...result, note })}
        value={result.note}
      />,
      <Input
        type="number"
        onChangeNumber={(checkedTotal) => {
          checkPlausibility(result.id);
          updateCheckResults({ ...result, checkedTotal });
        }}
        value={result.checkedTotal}
      />,
      <Input
        type="number"
        onChangeNumber={(io) => {
          checkPlausibility(result.id);
          updateCheckResults({ ...result, io });
        }}
        value={result.io}
      />,
      <Input
        type="number"
        onChangeNumber={(rework) => {
          checkPlausibility(result.id);
          updateCheckResults({ ...result, rework });
        }}
        value={result.rework}
      />,
      <Input
        type="number"
        onChangeNumber={(totalIoAfterRework) => {
          checkPlausibility(result.id);
          updateCheckResults({ ...result, totalIoAfterRework });
        }}
        value={result.totalIoAfterRework}
      />,

      ...errorPatterns.map((pattern) => (
        <Input
          value={result.foundErrorPatterns.get(pattern.id) || 0}
          type="number"
          onChangeNumber={(newValue) => {
            checkPlausibility(result.id);
            const existingPatterns: Map<string, number> =
              result.foundErrorPatterns;
            existingPatterns.set(pattern.id, newValue);
            updateCheckResults({
              ...result,
              foundErrorPatterns: existingPatterns,
            });
          }}
        />
      )),
      <div className="table-action__icon-wrapper">
        <TrashIcon
          className="delete"
          onClick={() => updateCheckResults(result, true)}
        />
      </div>,
    ],
  }));
};

/**
 * TODO - TEST ME
 * Util method to generate TableHeaders for the checkResults with default headers and additional headers based on the ErrorPatterns
 * @param errorPatterns array of errorpatterns to include
 * @returns Array of tableHeaders
 */
export const generateErrorPatternTableHeaders = (
  errorPatterns: ErrorPattern[]
): TableHeader[] => {
  const letters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const defaultHeaders: TableHeader[] = i18n.t(
    "pages.project.protocol.resultHeaders",
    {
      returnObjects: true,
    }
  ) as TableHeader[];
  const actionHeader: TableHeader = defaultHeaders[defaultHeaders.length - 1];
  defaultHeaders.pop();
  const patternHeaders: TableHeader[] = errorPatterns.map(
    (pattern) =>
      ({
        text: letters[pattern.index],
        visible: true,
        growFactor: 0.3,
        showSum: true,
      } as TableHeader)
  );
  return [...defaultHeaders, ...patternHeaders, actionHeader];
};

/**
 * Util method to generate an empty projectTimeBooking
 * @param override partial timeBooking to adjust the generated object
 * @returns  generated timeBooking
 */
export const generateEmptyProjectTimeBooking = (
  override: Partial<ProjectTimeBooking>
): ProjectTimeBooking => ({
  id: uid(),
  userId: "",
  startTime: 0,
  endTime: 0,
  userDrivingTime: 0,
  customerDrivingTime: 0,
  breakTime: 0,
  breakTimeUser: 0,
  note: "",
  customerArrival: false,
  customerDeparture: false,
  userArrival: false,
  userDeparture: false,
  scheduled: false,
  ...override,
});

/**
 * Util method to check if the data in a projectCheckResult is plausible or not
 * @param result to check
 * @returns boolean value if plausible
 */
/* eslint-disable no-fallthrough */
export const isResultPlausible = (result: ProjectCheckResult): boolean => {
  const errorAmount: number = result.foundErrorPatterns
    ? Array.from(result.foundErrorPatterns.values()).reduce((a, b) => a + b, 0)
    : 0;
  switch (true) {
    // no article number at all
    case !result.articleNumber:
    // total iO is smaller than iO
    case result.totalIoAfterRework < result.io:
    // total iO is larger than total checked amount
    case result.totalIoAfterRework > result.checkedTotal:
    // more iO after rework than iO and rework combined
    case result.totalIoAfterRework > result.rework + result.io:
    // less parts checked in total than rework
    case result.checkedTotal < result.rework:
    // more rework than actual errors
    case result.rework > errorAmount:
      return false;

    default:
      return true;
  }
};

/**
 * Util method to create empty project setttings
 * @param override partial ProtocolMailingSettings
 * @returns created object
 */
export const generateEmptyProtocolMailingSettings = (
  override?: Partial<ProtocolMailingSettings>
): ProtocolMailingSettings => ({
  setting: ProtocolDocumentConfig.FULL,
  receivers: [],
  ...override,
});

/**
 * Util method to create an empty projectSchedule
 * @param override partial ProjectSchedule to adjust the generated object
 * @returns created project schedule
 */
export const generateEmptySchedule = (
  override?: Partial<Schedule>
): Schedule => ({
  id: undefined!,
  orderId: "",
  referenceId: "",
  scheduleDate: undefined!,
  scheduledCars: new Map<ShiftType, string[]>(),
  scheduledUsers: new Map<ShiftType, string[]>(),
  type: ScheduleType.PROJECT_SCHEDULE,
  ...override,
});

/**
 * Helper to create a new instance of ScheduleAppointmentDetail. Keep in mind that this
 * object is not living on itself but needs a Schedule to live in
 *
 * @param override Optional override parameter
 * @returns A new ScheduleAppointmentDetail
 */
export const generateEmptyAppointmentDetails = (
  override?: Partial<ScheduleAppointmentDetail>
): ScheduleAppointmentDetail => ({
  id: uid(),
  title: "",
  description: "",
  location: "",
  reasonId: "",
  startTime: 0,
  endTime: 0,
  entireDay: false,
  state: AppointmentState.ABSENT,
  ...override,
});

/**
 * Util method to convert protocols into TableEntries
 * @param protocols to convert
 * @param customers to show in the table
 * @param users to show in the table
 * @param selectedProtocols list of all selected Protocols
 * @param setSelectedProtocols update method for the selectedProtocols
 * @returns  array of generated TableRows
 */
export const convertProtocolsIntoTableEntries = (
  protocols: ProjectProtocol[],
  customers: Customer[],
  users: SimpleUser[],
  selectedProtocols: string[],
  setSelectedProtocols: Dispatch<SetStateAction<string[]>>
): TableRow[] =>
  protocols.map((protocol) => {
    const customer: Customer | undefined = customers.find(
      (customer) => customer.id === protocol.customerId
    );
    return {
      id: protocol.id,
      content: [
        <Checkbox
          isChecked={selectedProtocols.includes(protocol.id)}
          onCheck={() =>
            setSelectedProtocols(
              selectedProtocols.includes(protocol.id)
                ? selectedProtocols.filter((id) => id !== protocol.id)
                : [...selectedProtocols, protocol.id]
            )
          }
        />,
        protocol.protocolDate.toLocaleDateString("de-DE"),
        customer?.name || "-",
        getUserNameForSimpleUser(protocol.createdBy, users),
      ],
    };
  });

/**
 * Util method to convert projects into tableEntries
 * @param projects to convert
 * @param navigate naviagteFunction
 * @returns  Array of TableRows
 */
export const convertRelatedProjectsIntoTableEntries = (
  projects: Project[],
  navigate: NavigateFunction
): TableRow[] => {
  return projects.map((project) => ({
    id: project.id,
    content: [
      project.numberRangeNumber,
      project.startDate?.toLocaleDateString("DE-de"),
    ],
    onClick: () => navigate("/project/edit", { state: { project } }),
  }));
};

/**
 * Helper method to get the next shift in an ascending or descending order
 * @param currentShift to get the next one for
 * @param availableShifts all shifts that are available
 * @param next boolean if the next shift should be given ascending or descending
 * @returns the next shiftType
 */
export const getNextShift = (
  currentShift: ShiftType,
  availableShifts: ShiftType[],
  next: boolean = false
): ShiftType => {
  //The order is important, becasue it is used to get the next shift
  const shifts: ShiftType[] = [
    ShiftType.EARLY,
    ShiftType.LATE,
    ShiftType.NIGHT,
    ShiftType.NORMAL,
  ];
  const currentIndex: number = shifts.findIndex(
    (shift) => currentShift === shift
  );
  if (next) {
    const indexToLoad: number =
      currentIndex === availableShifts.length - 1 ? 0 : currentIndex + 1;
    return shifts.filter((shift) => availableShifts.includes(shift))[
      indexToLoad
    ];
  } else {
    const indexToLoad: number =
      currentIndex === 0 ? availableShifts.length - 1 : currentIndex - 1;
    return shifts.filter((shift) => availableShifts.includes(shift))[
      indexToLoad
    ];
  }
};

/**
 * Util method to check if a shift is earlier or later compared to another one
 * @param oldShift to check
 * @param newShift  to check
 * @returns boolean if the type is earlier or not
 */
export const shiftIsEarlier = (
  oldShift: ShiftType,
  newShift: ShiftType
): boolean => {
  switch (newShift) {
    case ShiftType.EARLY:
      return true;
    case ShiftType.LATE:
      return oldShift === ShiftType.NIGHT || oldShift === ShiftType.NORMAL;
    case ShiftType.NIGHT:
      return oldShift === ShiftType.NORMAL;
    case ShiftType.NORMAL:
      return false;
  }
};
