import { Contact } from "../user/User.types";

/**
 * class for location of customers where contacts, the customer id and more information
 * are stored
 */
export interface CustomerLocation {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate: Date;
  updatedBy: string;
  name: string;
  contact: Contact;
  disabled: boolean;
  annotation: string;
  customerId: string;
  contacts: string[];
  officeDistances: Map<string, number>;
  officeDrivingTimes: Map<string, number>;
  officeDrivingFlatRate: Map<string, number>;
  ownLocation: boolean;
}

/**
 * Class that describes different shifts at a customerLocation
 */
export interface Shift {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate: Date;
  updatedBy: string;
  type: ShiftType;
  customerId: string;
  locationId: string;
  //start and endTime are stored as minutes from 00:00
  startTime: number;
  endTime: number;
}

/**
 * enum for different shiftTypes
 * !The order of the enum is used to display the Shifts in the UI!
 */
export enum ShiftType {
  EARLY = "EARLY",
  LATE = "LATE",
  NIGHT = "NIGHT",
  NORMAL = "NORMAL",
}
