import { ProjectPlanningProps } from "./ProjectPlanning.types";
import { DateHeader } from "./subcomponents/PlanningHeader.tsx";

import "./ProjectPlanning.scss";
import { AvailableUsers } from "./subcomponents/AvailableUsers.tsx";
import { CarsAvailable } from "./subcomponents/CarsAvailable.tsx";
import { MainTable } from "./subcomponents/MainTable.tsx";
import { Schedule } from "shared";
import { useMemo } from "react";

export const ProjectPlanning: React.FC<ProjectPlanningProps> = ({
  cars,
  projects,
  schedules,
  startDate,
  users,
  customers,
  translations,
  isEdit,
  onScheduleUpdate,
  customerLocations,
  orders,
  checkWorkerAmount,
  absenceReasons,
}) => {
  /**
   * Holds the schedules that are either connected to a available user or a project
   */
  const relevantSchedules: Schedule[] = useMemo((): Schedule[] => {
    return schedules.filter(
      (schedule) =>
        (!schedule.appointmentDetail &&
          projects.some((project) => project.id === schedule.referenceId)) ||
        users.some((user) => user.id === schedule.referenceId)
    );
  }, [projects, schedules, users]);
  return (
    <div className="project-planning__wrapper">
      <DateHeader
        users={users}
        startDate={startDate}
        projectTitle={translations.projectTitle}
        schedules={relevantSchedules}
        translations={translations}
      />
      <MainTable
        customerLocations={customerLocations}
        cars={cars}
        customers={customers}
        projects={projects}
        schedules={relevantSchedules}
        startDate={startDate}
        users={users}
        translations={translations}
        isEdit={isEdit}
        onScheduleUpdate={onScheduleUpdate}
        orders={orders}
        checkWorkerAmount={checkWorkerAmount}
      />
      <AvailableUsers
        schedules={relevantSchedules}
        startDate={startDate}
        users={users}
        translations={translations}
        absenceReasons={absenceReasons}
      />
      <CarsAvailable
        cars={cars}
        schedules={schedules}
        startDate={startDate}
        carsHeader={translations.availableCarsHeader}
      />
    </div>
  );
};
