import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import { Language, mapReplacer, Period } from "..";
import i18n from "../../../client/src/i18n/i18n";
import { ShiftType } from "../customerLocation/CustomerLocation.types";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { Schedule } from "../project/Project.types";
import {
  ProjectProtocol,
  ProjectTimeBooking,
  ProtocolConfig,
} from "./ProjectProtocol.types";

/**
 * Util method to get a protocol by the date and projectId
 * @param axios instance of axios
 * @param projectId id of the project to load the protocol for
 * @param date date of the protocol to load
 * @returns Found Protocol or undefined if nothing was found
 */
export const getProtocolByDateAndProjectIdAndShift = async (
  axios: AxiosInstance,
  projectId: string,
  date: Date,
  shift: ShiftType
): Promise<ProjectProtocol | undefined> => {
  return axios
    .get("/project/protocol/date", {
      params: { projectId, date: date.toISOString().slice(0, 10), shift },
    })
    .then((res) => {
      if (res.status === 204) {
        generateNotification({
          type: NotificationType.INFO,
          value: i18n.t("general.notification.info.protocolNoSchedule"),
        });
        return undefined;
      }
      return res.data;
    })
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getProtocol"),
      });
      console.error("Error while loading projectProtocol", exc);
    });
};

/**
 * API Method to update a protocol on the database
 * @param axios instance of axios
 * @param protocol updated Protocol
 * @returns updated protocol or undefined on error
 */
export const updateProtocol = async (
  axios: AxiosInstance,
  protocol: ProjectProtocol
): Promise<ProjectProtocol | undefined> => {
  return axios
    .post("project/protocol/update", JSON.stringify(protocol, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.updateProjectProtocol"),
      });
      console.error("Error while updating protocol", exc);
    });
};

/**
 * API method to create a new ProjectSchedule
 * @param axios instance of axios
 * @param schedule schedule to save
 * @returns  Created Schedule or undefined on error
 */
export const createNewSchedule = async (
  axios: AxiosInstance,
  schedule: Schedule
): Promise<Schedule | undefined> => {
  return axios
    .post("/project/schedule/", JSON.stringify(schedule, mapReplacer))
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating schedule", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewSchedule"),
      });
    });
};

/**
 * API method to create a new projectProtocol
 * @param axios instance of axios
 * @param shift shift of the protocol to create
 * @param projectId id of the project connected to the protocol
 * @param date date of the protocol to create
 * @returns
 */
export const createNewProjectProtocol = async (
  axios: AxiosInstance,
  shift: ShiftType,
  projectId: string,
  date: Date,
  schedule: Schedule
): Promise<ProjectProtocol | undefined> => {
  return axios
    .post(
      "/project/protocol/",
      JSON.stringify(
        {
          shift,
          projectId,
          date,
          schedule,
        } as ProtocolConfig,
        mapReplacer
      )
    )
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating protocol", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createNewProtocol"),
      });
    });
};

/**
 * Util method to load the protocol pdf docuemtn
 * @param axios  instance of axios
 * @param projectId id of the project to create the docuemnt for
 * @returns  Blob with document
 */
export const getProtocolPDF = async (
  axios: AxiosInstance,
  projectId: string
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/protocol", {
      params: {
        projectId,
      },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.downloadProtocol"),
      });
      console.error("Error while loading document", exc);
      return undefined;
    });
};

/**
 * API Method to generate multiple protocol documents in a zip file
 * @param axios instance of axios
 * @param protocolIds List of included protocols
 * @returns Promise containing the ifle or undefined on error
 */
export const getMultipleProtocolPdfs = async (
  axios: AxiosInstance,
  projectIds: string[],
  period: Period,
  language: Language
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/protocol/multiple", {
      params: {
        projectIds: projectIds.toString(),
        startDate: period.startDate.toISOString().split("T")[0],
        endDate: period.endDate.toISOString().split("T")[0],
        language,
      },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/zip" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createMultipleProtocolPdf"),
      });
      console.error("Error while creating documents", exc);
      return undefined;
    });
};
/**
 * Util method to get all Protocols in for multiple projects
 * @param axios instance of axios
 * @param endDate end of the range to load the protocols. Start is the lastInvoiced date from the order
 * @param projects list of projects to load the protocols
 * @returns List of all found protocols
 */
export const getAllProtocolsForMultipleProjects = async (
  axios: AxiosInstance,
  endDate: Date,
  projects: string[]
): Promise<ProjectProtocol[]> => {
  return axios
    .get("/project/protocol/multiple", {
      params: {
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        projectIds: projects.toString(),
      },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading protocols", exc);
      return [];
    });
};

/**
 * API Method to load all protocols in a given range
 * @param axios instance of axios
 * @param period containing the start and endDate
 * @returns Promise containing an array with all found Entries
 */
export const getAllProtocolsInRange = async (
  axios: AxiosInstance,
  period: Period
): Promise<ProjectProtocol[]> => {
  return axios
    .get("/project/protocol/range", {
      params: {
        startDate: dayjs(period.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(period.endDate).format("YYYY-MM-DD"),
      },
    })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading protocols", exc);
      return [];
    });
};

/**
 * API Method to get the latest timeBooking for a user on a project
 * @param axios instance of axios
 * @param projectId id of the project to check
 * @param userId user to get the schedule for
 * @returns Promise containing the last timeBooking or undefined if the user was not planned in the project
 */
export const getLatestUserTimeBookingForProject = async (
  axios: AxiosInstance,
  projectId: string,
  userId: string
): Promise<ProjectTimeBooking | undefined> => {
  return axios
    .get("/project/protocol/latest", { params: { projectId, userId } })
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while loading last timeBooking", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getLatestTimeBooking"),
      });
    });
};
