import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { Schedule, ScheduleType, SimpleUser } from "shared";
import { ProjectPlanningTranslations } from "../ProjectPlanning.types";
import { getAbsentUserList } from "./PlanningBoard.util";

interface DateHeaderProps {
  startDate: dayjs.Dayjs;
  projectTitle: string;
  users: SimpleUser[];
  schedules: Schedule[];
  translations: ProjectPlanningTranslations;
}

export const DateHeader: React.FC<DateHeaderProps> = ({
  startDate,
  projectTitle,
  users,
  schedules,
  translations,
}) => {
  /**
   * Holds the actual dates to display in the header
   */
  const entries: dayjs.Dayjs[] = useMemo((): dayjs.Dayjs[] => {
    const endDate: dayjs.Dayjs = startDate.add(4, "day");
    const targetArray: dayjs.Dayjs[] = [];
    for (
      let currDate = startDate;
      currDate.isBefore(endDate) || currDate.isSame(endDate);
      currDate = currDate.add(1, "day")
    )
      targetArray.push(currDate);
    return targetArray;
  }, [startDate]);

  /**
   * Helper to get theamount of users which are scheduled for the given day
   *
   * @param date The date to check for
   */
  const getScheduledUserAmount = useCallback(
    (date: dayjs.Dayjs) => {
      const schedulesToday: Schedule[] = schedules.filter(
        (schedule) =>
          dayjs(schedule.scheduleDate).isSame(date, "day") &&
          schedule.type === ScheduleType.PROJECT_SCHEDULE
      );
      const scheduledUsers: string[] = [];
      schedulesToday.forEach((scheduleToday) => {
        Array.from(scheduleToday.scheduledUsers.values())
          .flat()
          .forEach(
            (user) =>
              !scheduledUsers.includes(user) && scheduledUsers.push(user)
          );
      });
      return scheduledUsers.length;
    },
    [schedules]
  );

  /**
   * Helper to get the amount of absent users
   */
  const getAbsentUsersAmount = useCallback(
    (date: dayjs.Dayjs) => getAbsentUserList(schedules, date).length,
    [schedules]
  );

  /**
   * Helper to extract the amount of actual available users for the given day
   */
  const getAvailableUserAmount = useCallback(
    (date: dayjs.Dayjs): string => {
      // check which user is scheduled
      const scheduledUserAmount: number = getScheduledUserAmount(date);
      // check which user is absent
      const absentUserAmount: number = getAbsentUsersAmount(date);

      return `${translations.planned} ${scheduledUserAmount} / ${
        users.length - absentUserAmount
      }`;
    },
    [
      getAbsentUsersAmount,
      getScheduledUserAmount,
      translations.planned,
      users.length,
    ]
  );

  return (
    <div className="project-planning__header-wrapper">
      <div className="project-planning__header-wrapper__header">
        <p className="project-planning__header-wrapper__header__offer">
          {projectTitle}
        </p>
        {entries.map((entry) => (
          <p
            className={`project-planning__header-wrapper__header__date ${
              entry.day() === 6 || entry.day() === 0 ? "weekend-row" : ""
            }`}
          >
            {entry.format("dd, DD.MM.")}
            <p>{getAvailableUserAmount(entry)}</p>
          </p>
        ))}
      </div>
    </div>
  );
};
