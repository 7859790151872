import { AxiosInstance } from "axios";
import i18n from "../../../client/src/i18n/i18n";
import { InvoiceState, Receipt, ReceiptType } from "../invoice/Invoice.types";
import { DunningMailConfiguration, ReceiptConfig } from "../mail/Mail.types";
import { NotificationType } from "../notification/notification.types";
import { generateNotification } from "../notification/notification.util";
import { Language } from "../Language/Language.types";

/**
 * API Method to create a new creditNote
 * @param axios instance of axios
 * @param receipt new Invoice that is used to calculate the creditNote !This invoice does not get persistet on the db, it is only to calculate a credit note, which is saved!
 * @returns Promise containing the receipt with type credit Note
 */
export const createNewCreditNoteReceipt = async (
  axios: AxiosInstance,
  receipt: Receipt
): Promise<Receipt | undefined> => {
  return axios
    .post("/receipt/credit", receipt)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new receipt", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createCreditNote"),
      });
    });
};

/**
 * API method to create a new cancel receipt, which is a copy of the originalInvoice with negative values and a new invoice
 * @param axios instance of axios
 * @param receipt new Invoice, the is created after the old invoice was cancelled
 * @returns Promise containing the cancelReceipt
 */
export const createNewCancelReceipt = async (
  axios: AxiosInstance,
  receipt: Receipt
): Promise<Receipt | undefined> => {
  return axios
    .post("/receipt/cancel", receipt)
    .then((res) => res.data)
    .catch((exc) => {
      console.error("Error while creating new receipt", exc);
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createCancelReceipt"),
      });
    });
};

/**
 * API Method to create and send an cancelReceipt
 * @param axios API Method to create a new cancel receipt and send it via mail
 * @param config new Receipt and mailConfiguration
 * @returns Promise containng the cancelReceipt
 */
export const createAndSendCancelReceipt = async (
  axios: AxiosInstance,
  config: ReceiptConfig
): Promise<Receipt | undefined> => {
  return axios
    .post("/mail/cancel", config)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createAndSendReceipt"),
      });
      console.error("Error while creating cancelReceipt", exc);
      return undefined;
    });
};

/**
 * API Method to create and send a credit note
 * @param axios API Method to create a new credit note and send it via mail
 * @param config new Receipt and mailConfiguration
 * @returns Promise containng the credit note
 */
export const createAndSendCreditNote = async (
  axios: AxiosInstance,
  config: ReceiptConfig
): Promise<Receipt | undefined> => {
  return axios
    .post("/mail/credit", config)
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createAndSendReceipt"),
      });
      console.error("Error while creating and sending credit note", exc);
      return undefined;
    });
};

/**
 * API Method to get all receipts by the receiptType
 * @param axios instance of axios
 * @param types type of the receipts to load
 * @returns  Promise containing an array of all found receipts
 */
export const getAllReceiptsByTypeAndState = async (
  axios: AxiosInstance,
  types: ReceiptType[],
  states: InvoiceState[]
): Promise<Receipt[]> => {
  return axios
    .get("/receipt/all/type/state", {
      params: { types: types.toString(), states: states.toString() },
    })
    .then((res) => res.data)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.getReceiptsByType"),
      });
      console.error("Error while loading receipts for type ", types, exc);
      return [];
    });
};

/**
 * API Mehtod to create and send multiple dunnings
 * @param axios instance of axios
 * @param config mailConfig, invoice ids and userId to send the mail
 * @returns Promise containing a boolean for the success
 */
export const createAndSendDunning = async (
  axios: AxiosInstance,
  config: DunningMailConfiguration
): Promise<boolean> => {
  return axios
    .post("/mail/dunning", config)
    .then((res) => res.status === 200)
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: i18n.t("general.notification.error.createAndSendDunnings"),
      });
      console.error("Error while sending dunning messages", exc);
      return false;
    });
};

/**
 * API method to get a dunning document as a preview
 * @param axios instance of axios
 * @param invoiceId id of the receipt to get the preview
 * @param userId id of the user that creates the document
 * @param language to create the document in
 * @returns Blob containing the dunning document
 */
export const getDunningPreviewDocument = async (
  axios: AxiosInstance,
  receiptId: string,
  userId: string,
  language: Language
): Promise<Blob | undefined> => {
  return axios
    .get("/export/pdf/dunning", {
      params: { receiptId, language, userId },
      responseType: "blob",
    })
    .then((res) => new Blob([res.data], { type: "application/pdf" }))
    .catch((exc) => {
      generateNotification({
        type: NotificationType.ERROR,
        value: "general.notification.error.getDunningPreview",
      });
      console.error("Error while loading dunning preview", exc);
      return undefined;
    });
};
