import { Input, TableRow } from "@sam/components";
import { Language } from "shared/src/Language/Language.types";
import i18n from "../i18n/i18n";

/**
 * Helper to quickly create table entries for a specific field that should get
 * translated into all Languages
 * @param objectToEdit The object to edit
 * @param translatedKey The key which should create the language table.
 *                      **CAUTION**: this has to be a Map<Language, string>
 * @param onUpdate Callback function to handle the input update
 * @returns The table entries
 */
export const generateTableRowForLanguageTranslation = <T,>(
  objectToEdit: T,
  translatedKey: keyof T,
  onUpdate: (updatedObject: T) => void
): TableRow[] => {
  return Object.values(Language).map((language, index) => ({
    id: language + index,
    content: [
      i18n.t(`general.languages.${language}`),
      <Input
        type="text"
        value={(objectToEdit[translatedKey] as Map<Language, string>)?.get(
          language
        )}
        onChange={(name) => {
          const entryNames: Map<Language, string> = new Map<Language, string>(
            objectToEdit[translatedKey] as Map<Language, string>
          );
          entryNames.set(language, name);
          onUpdate({ ...objectToEdit, [translatedKey]: entryNames });
        }}
      />,
    ],
  }));
};
