import {
  Box,
  Button,
  Checkbox,
  Input,
  Popup,
  Table,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { generateNotification } from "shared";
import {
  createNewCurrency,
  createNewCurrencyConversion,
  getAllConversionsForCurrency,
  updateCurrency,
} from "shared/src/currency/Currency.axios";
import {
  Currency,
  CurrencyConversion,
} from "shared/src/currency/Currency.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import {
  convertConversionsIntoTableEntries,
  generateEmptyCurrency,
} from "../../utils/currency/Currency.utils";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";

export const CurrencyEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const location = useLocation<{ currency?: Currency }>();

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [currencyToEdit, setCurrencyToEdit] = useState<Currency>(
    location.state?.currency ? location.state.currency : generateEmptyCurrency()
  );
  const [conversions, setConversions] = useState<CurrencyConversion[]>([]);
  const [conversionToAdd, setConversionToAdd] = useState<CurrencyConversion>();
  /**
   * TableRows to be displayed with all conversions
   */
  useEffect(() => {
    if (!currencyToEdit.id) return;
    else
      getAllConversionsForCurrency(axios, currencyToEdit.id).then(
        setConversions
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyToEdit.id]);

  /**
   * Holds rows with currencyConversions for the active currency
   */
  const conversionRows = useMemo(
    () => convertConversionsIntoTableEntries(conversions),
    [conversions]
  );

  /**
   * Submit handler to create or update a currency on submit
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (currencyToEdit.id) {
      updateCurrency(axios, currencyToEdit).then((updatedCurrency) => {
        if (updatedCurrency && redirect) navigate("/currency");
        else if (updatedCurrency) {
          setCurrencyToEdit(updatedCurrency);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
    } else {
      createNewCurrency(axios, currencyToEdit).then((createdCurrency) => {
        if (createdCurrency && redirect) navigate("/currency");
        else if (createdCurrency) {
          setCurrencyToEdit(createdCurrency);
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.saveSuccessfull"),
          });
        }
      });
    }
  };
  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        onBackClick={() => navigate(-1)}
        title={t(
          `pages.currency.edit.topBarHeadline${
            currencyToEdit.id ? "Edit" : "Create"
          }`
        )}
      >
        <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
      </TopBar>
      {conversionToAdd && (
        <Popup
          isOpen={!!conversionToAdd}
          onClose={() => setConversionToAdd(undefined)}
          buttons={[
            <Button
              value={t("general.buttons.cancel")}
              onClick={() => setConversionToAdd(undefined)}
            />,
            <Button
              value={t("general.buttons.save")}
              onClick={() =>
                createNewCurrencyConversion(axios, conversionToAdd).then(
                  (savedConversion) => {
                    savedConversion &&
                      setConversions([...conversions, savedConversion]);
                    setConversionToAdd(undefined);
                  }
                )
              }
            />,
          ]}
        >
          <Checkbox
            onCheck={(active) =>
              setConversionToAdd({ ...conversionToAdd, active })
            }
            isChecked={conversionToAdd.active}
            label={t("pages.currency.edit.active")}
          />
          <Input
            label={t("pages.currency.edit.factor")}
            type="number"
            value={conversionToAdd?.factor}
            onChangeNumber={(factor) =>
              setConversionToAdd({ ...conversionToAdd, factor })
            }
          />
        </Popup>
      )}
      <Box>
        <div className="three-columns">
          <Input
            type="text"
            onChange={(title) =>
              setCurrencyToEdit({ ...currencyToEdit, title })
            }
            label={t("pages.currency.edit.title")}
            value={currencyToEdit.title}
          />
          <Input
            type="text"
            onChange={(symbol) =>
              setCurrencyToEdit({ ...currencyToEdit, symbol })
            }
            label={t("pages.currency.edit.symbol")}
            value={currencyToEdit.symbol}
          />
          <Input
            type="text"
            onChange={(intlCode) =>
              setCurrencyToEdit({ ...currencyToEdit, intlCode })
            }
            label={t("pages.currency.edit.intlCode")}
            value={currencyToEdit.intlCode}
          />
          <Checkbox
            label={t("pages.currency.edit.deactivated")}
            isChecked={currencyToEdit.disabled}
            onCheck={(disabled) =>
              setCurrencyToEdit({ ...currencyToEdit, disabled })
            }
          />
        </div>
      </Box>
      <Box>
        {currencyToEdit.intlCode === "CZK" && (
          <AddIcon
            className="currency-edit__add"
            onClick={() =>
              setConversionToAdd({
                active: true,
                currencyId: currencyToEdit.id,
                factor: 1,
                id: undefined!,
                createDate: new Date(),
              })
            }
          />
        )}
        <Table
          rows={conversionRows}
          header={
            t("pages.currency.edit.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </form>
  );
};
