import { Language } from "../Language/Language.types";
import { Shift } from "../customerLocation/CustomerLocation.types";
import {
  PaymentCondition,
  ProjectInvoiceType,
  UnitCode,
} from "../invoice/Invoice.types";
import { Offer, OfferType } from "../offer/Offer.types";
import { ProtocolDocumentConfig } from "../projectProtocol/ProjectProtocol.types";

/**
 * Interface for orders including the orderstate and the task
 */
export interface Order {
  id: string;
  createDate: Date;
  createdBy: string;
  lastUpdate: Date;
  updatedBy: string;
  customerId: string;
  customerContact: string;
  locationId: string;
  locationContactId: string;
  officeId: string;
  orderState: OrderState;
  additionalReceivers?: string[];
  shifts: Shift[];
  companyId: string;
  businessAreaId: string;
  lastInvoiced?: Date;
  invoiceRecipient: InvoiceRecipient;
  customerFeedback: Map<string, Map<string, number>>;
  workingLocation: WorkingLocation;
  workingLocationComment: string;
  customerOrderNumber: string;
  pieces: number;
  invoiceType: ProjectInvoiceType;
  currencyId: string;
  customerArticleNumber: string;
  customerArticleDescription: string;
  paymentCondition?: PaymentCondition;
  offerType: OfferType;
  originatedFrom?: string;
  withoutInvoice?: boolean;
  standardPriced?: boolean;
  invoiceDocumentConfig?: ProtocolDocumentConfig;
  lastProtocolDate?: Date;
  protocolSendDates?: Date[];
}

/**
 * Interface for a invoice recipient containing the customer and customerUser id
 */
export interface InvoiceRecipient {
  customerId: string;
  customerLocationId: string;
  customerUserId: string;
}

export interface WorkingLocation {
  customerId: string;
  customerLocationId: string;
  customerContactId: string;
}
/**
 * Interface for different tasks that could be created and edited (the so called
 * "Artikel" on the frontend)
 */
export interface Task {
  id: string;
  createdBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
  lastUpdate?: Date;
  title: string;
  price: number;
  identifier: string;
  unit: string;
  disabled: boolean;
  translations: Map<Language, string>;
  unitCode: UnitCode;
  /**
   * is needed if eg 50 pieces are sold with one unit
   */
  amount: number;
  annotation: string;
  /**
   * If this value is set to true this instance has to be on every newly created
   * offer per default
   */
  standard: boolean;
  /**
   * This field defaults to null. Only standard prices have a value set. Each type
   * is only allowed once
   */
  standardType?: StandardPriceType;
  /**
   * Only used by the client to signal the controller to disregard the check for
   * the standard type. This is needed for the confirmed round trip from the
   * client to successfully save the task
   */
  withoutStandardCheck?: boolean;
  /**
   * Holds the office specific prices for specific currencies. First identifier is the office id, then the currencyId
   */
  officePrices?: Map<string, Map<string, number>>;
  /**
   * Not used currently, implemented on customer's request
   */
  piecePerHour?: number;
}

/**
 * This constant is used to define if a task is a standard pricing for the given
 * type. Each type is only allowed to exist once.
 */
export enum StandardPriceType {
  CHECK_SORT_TEAM = "CHECK_SORT_TEAM",
  CHECK_SORT_LEADER = "CHECK_SORT_LEADER",
  WORK_NIGHT = "WORK_NIGHT",
  WORK_SATURDAY = "WORK_SATURDAY",
  WORK_SUNDAY = "WORK_SUNDAY",
  WORK_HOLIDAY = "WORK_HOLIDAY",
  DRIVING_TIME = "DRIVING_TIME",
  STORAGE_AREA = "STORAGE_AREA",
}

/**
 * enum for possible orderStates that show the active state of an order
 */
export enum OrderState {
  OFFER = "OFFER",
  OFFER_DENIED = "OFFER_DENIED",
  OFFER_ACCEPTED = "OFFER_ACCEPTED",
  PROHECT_START = "PROJECT_START",
  PROJECT_RUNNING = "PROJECT_RUNNING",
  PROJECT_COMPLETED = "PROJECT_COMPLETED",
  CLOSED_BY_SCHAMBECK = "CLOSED_BY_SCHAMBECK",
  OFFER_PREP = "OFFER_PREP",
}
/**
 * Combined object with order and offer to be sent to the backend
 */
export interface OrderWithOffer {
  order: Order;
  offer: Offer;
}

/**
 * enum for different numberRanges to keep track of the values
 */
export enum NumberRangeType {
  INTERN = "INTERN",
  OFFER = "OFFER",
  FRAMEWORK = "FRAMEWORK",
  ORDER = "ORDER",
  PROTOCOL = "PROTOCOL",
  INVOICE = "INVOICE",
  CANCELLATION_INVOICE = "CANCELLATION_INVOICE",
  REFUND = "REFUND",
  INTERN_CANCELLATION = "INTERN_CANCELLATION",
}

/**
 * interface for numberRanges that are keeping track of the value
 */
export interface NumberRange {
  id: string;
  createDate: Date;
  createdBy: string;
  updatedBy: string;
  lastUpdate: Date;
  type: NumberRangeType;
  value: number;
  companyId: string;
}

/**
 * Different types a customer could be connected to a order
 */
export enum CustomerType {
  INVOCIE_RECIPIENT = "INVOICE_RECIPIENT",
  WORKING_LOCATION = "WORKING_LOCATION",
  CUSTOMER = "CUSTOMER",
}
